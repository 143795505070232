import { getKpisBySession } from "./getKpisBySession";

/**
 * Calculates the gap to the fastest lap time in each session.
 *
 * @param {TalentIdSession[]} sessions - The array of sessions.
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The authentication token.
 * @returns {Promise<Object>} - The graph data object or null if there are no points.
 */
export const gapToFastestGraph = async (sessions, userId, ajaxHelper, token) => {
    const points = { seconds: {}, percentage: {} };
    for (const session of sessions) {
        const session_kpis = await getKpisBySession(session.id, "BestLap_Session_Rank", ajaxHelper, token);
        const sorted_session_kpis = session_kpis.sort((a, b) => a.metadata.bestLap.lapTime - b.metadata.bestLap.lapTime);
        const mapped_session_kpis = sorted_session_kpis.map(kpi => {
            return {
                userId: kpi.userId,
                gapToFastestSeconds: (kpi.metadata.bestLap.lapTime - sorted_session_kpis[0].metadata.bestLap.lapTime) / 1000,
                gapToFastestPercentage: (kpi.metadata.bestLap.lapTime - sorted_session_kpis[0].metadata.bestLap.lapTime) / sorted_session_kpis[0].metadata.bestLap.lapTime * 100
            };
        });
        for (const kpi of mapped_session_kpis) {
            if (!(kpi.userId in points.seconds)) {
                points.seconds[kpi.userId] = [];
                points.percentage[kpi.userId] = [];
            }
            points.seconds[kpi.userId].push([session.name, kpi.gapToFastestSeconds, kpi.userId === userId]);
            points.percentage[kpi.userId].push([session.name, kpi.gapToFastestPercentage, kpi.userId === userId]);
        }
    }

    const unwrapped_points_seconds = [];
    for (const user in points.seconds) {
        unwrapped_points_seconds.push(points.seconds[user]);
    }
    const unwrapped_points_percentage = [];
    for (const user in points.percentage) {
        unwrapped_points_percentage.push(points.percentage[user]);
    }
    const unwrapped_points = {
        seconds: unwrapped_points_seconds,
        percentage: unwrapped_points_percentage
    };

    if (unwrapped_points.seconds.length === 0) return null;

    return {
        name: "GapToFastest",
        displayName: "Gap To Best Lap Times",
        description: "This graph shows your gap to the fastest lap time in each session.\nIf you were the fastest in that session, your gap will be 0!",
        yLabel: "Gap to Fastest",
        xLabel: "Session",
        points: unwrapped_points,
        type: "toggleableGraphical"
    };

};
