import { TalentIdKpi } from "../models/TalentIdKpi";

/**
 * Retrieves a statistic for a user.
 *
 * @param {string} groupIdPrefix - The prefix for the group ID.
 * @param {string} statisticName - The name of the statistic.
 * @param {number[]} sessionTypes - An array of session types.
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The authentication token.
 * @param {string} units - The units of the statistic.
 * @param {number} [conversionFactor=1] - The conversion factor for the statistic.
 * @returns {Promise<Object|null>} The statistic object, or null if the statistic is not available.
 */

export const getStatistic = async (groupIdPrefix, statisticName, sessionTypes, userId, ajaxHelper, token, units, conversionFactor = 1) => {
    const groupId = groupIdPrefix + "_" + userId;
    const response = await ajaxHelper.get("/Kpi/byUser/" + userId, token, { groupId });
    const kpis = response.data.map(kpi => new TalentIdKpi(kpi));
    const stat = kpis.filter(kpi => sessionTypes.includes(kpi.metadata.sessionType)).map((kpi) => kpi.metadata.value).reduce((partialSum, a) => partialSum + a, 0);
    if (!(stat) || stat === 0) {
        return null;
    }
    return {
        name: statisticName,
        value: stat * conversionFactor,
        units: units
    };
};
