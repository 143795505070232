import '../static/css/nav.css'
import talentid_logo from '../static/img/Talent_ID_Transparent.svg'
import { useAuth } from '../provider/authProvider';
import Form from './NavComponents/Form';
import { useNavigate } from 'react-router-dom';
import { path } from 'd3';
/**
 * Navigation bar component for the web application.
 * 
 * @returns {JSX.Element} The navigation bar component.
 */
export default function NavBar() {
    var current_page = window.location.pathname;

    const { token, setToken } = useAuth();
    const navigate = useNavigate(); 

    const logout = async (e) => {
        e.preventDefault();
        setToken();
        sessionStorage.clear();
        navigate("/");
    }

    const navigateLink = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    window.onclick = function(event) {
        if (!event.target.closest('.dropdown')) {
            hideMenu();
        }
    }
    function hideMenu() {
        var dropdown = document.getElementsByClassName("dropdown-content");
        if (dropdown.length === 0) {
            return;
        }
        dropdown = dropdown[0];
        dropdown.style.display = "none";
        var input = document.getElementsByClassName("menu-input")[0];
        input.classList.remove("menu-pressed");
        input.classList.add("menu-unpressed");

    }
    function toggleMenu() {
        var dropdown = document.getElementsByClassName("dropdown-content")[0];
        dropdown.style.display = dropdown.style.display === "block" ? "none" : "block";
        var input = document.getElementsByClassName("menu-input")[0];
        input.classList.toggle("menu-pressed");
        input.classList.toggle("menu-unpressed");
    }

    const getClass = (path, mobileOnly) => {
        if (current_page === path && mobileOnly) {
            return "mobile-nav-link selected";
        } else if (current_page === path && !mobileOnly) {
            return "selected";
        } else if (mobileOnly) {
            return "mobile-nav-link";
        } else {
            return "";
        }
    }

    if (token) {
        return (
            <header>
                <div className="gkl-logo-container">
                    <a href="/dashboard">
                        <img className="gkl-logo" src={talentid_logo} alt="TalentID Logo"/>
                    </a>
                </div>
                <nav>
                    <Form path="/" value="HOME" current_page={current_page} />
                    <Form path="/help" value="USER GUIDE" current_page={current_page} />
                    <Form path="/dashboard" value="EVENT OVERVIEW" current_page={current_page} />
                    <Form path="/kpi" value="INSIGHTS" current_page={current_page} />
                    <Form path="/send-feedback" value="SEND FEEDBACK" current_page={current_page} />
                    <div className="user-options-container">
                        <div className="dropdown">
                            <button className="dropbtn" onClick={toggleMenu}>
                                <div className="menu-container">
                                    <input type="submit" className="menu-input menu-unpressed" value={token.name.toUpperCase()} />
                                </div>
                            </button>
                            <div className="dropdown-content">
                                <a className={getClass("/", true)} onClick={(e) => {navigateLink(e, '/')}}>HOME</a>
                                <a className={getClass("/help", true)} onClick={(e) => {navigateLink(e, '/help')}}>USER GUIDE</a>
                                <a className={getClass("/dashboard", true)} onClick={(e) => {navigateLink(e, '/dashboard')}}>EVENT OVERVIEW</a>
                                <a className={getClass("/kpi", true)} onClick={(e) => {navigateLink(e, '/kpi')}}>INSIGHTS</a>
                                <a className={getClass("/send-feedback", true)} onClick={(e) => {navigateLink(e, '/send-feedback')}}>SEND FEEDBACK</a>
                                <a className={getClass("/account", false)} onClick={(e) => {navigateLink(e, '/account')}}>ACCOUNT</a>
                                <a onClick={logout}>LOGOUT</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }

    const onClick = (e, path) => {
        e.preventDefault();
        navigate(path);
    }
    return (
        <header>
            <div className="gkl-logo-container">
                <a href="/">
                    <img className="gkl-logo" src={talentid_logo} alt="TalentID Logo"/>
                </a>
            </div>
            <nav>
                <form onClick={function(e) {onClick(e, "/")}}>
                    <input type="submit" value="HOME" className={current_page === "/" ? "selected" : ""} />
                </form>
                <form onClick={function(e) {onClick(e, "/login")}} path='/login'>
                    <input type="submit" value="LOGIN" className={current_page === "/login" ? "selected" : ""} />
                </form>
                {/* <Form path="/" value="HOME" current_page={current_page} />
                <Form path="/login" value="LOGIN" current_page={current_page} /> */}
            </nav>
        </header>
    );
}