import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react";
import { AjaxHelper } from "../utils/AjaxHelper";
import { Buffer } from 'buffer';
import '../static/css/auth.css';
import NavBar from "./NavBar";
import Footer from "./Footer";
import validateForm from "./AuthUtils/passwordValidation";

/**
 * Register component for the web application.
 * @param {Object} props - The properties of the component.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The register form component.
 */
export default function ResetPassword({ ajaxHelper }) {
    const { userId, email } = useParams();
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const encodedPassword = Buffer.from(password).toString('base64');
            const token = new URLSearchParams(window.location.search).get("token");
            // Send credentials to API
            const response = await ajaxHelper.post("/Credentials/resetPassword", {
                userId,
                email,
                password: encodedPassword
            }, token);
            const { data } = response;
            console.log(data);
            alert("Password reset successfully. Please login to continue.");
            navigate("/login");
        } catch(err) {
            // Invalid login 
            const { response } = err;
            if (response) {
                // Display error message
                const message = "Something went wrong. Please try again.";
                const authErrors = document.querySelector(".auth-errors");
                authErrors.innerHTML = `<p>${message}</p>`;
            } else {
                // Display generic error message
                const authErrors = document.querySelector(".auth-errors");
                authErrors.innerHTML = "<p>Something went wrong. Please try again later.</p>";
            }
        }
    }

    return (
        <div className="auth-page">
        <NavBar />
        <main>
            <section id="reset-password">
                <form onSubmit={handleSubmit}>
                    <div className="field-container">
                    <p>
                        <label htmlFor="password">PASSWORD</label>
                        <input id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} onKeyUp={function(e) {return validateForm(e.target.value, null, null)}} required />
                    </p>
                    </div>
                    <div class="password-constraints-centred" id="password-constraints" style={{display: "none"}}>
                        <div id="Length" class="glyphicon glyphicon-remove">Must be longer than 8 charcters</div>
                        <div id="UpperCase" class="glyphicon glyphicon-remove">Must have at least 1 upper case character</div>
                        <div id="LowerCase" class="glyphicon glyphicon-remove">Must have at least 1 lower case character</div>
                        <div id="Numbers" class="glyphicon glyphicon-remove">Must have at least 1 numeric character</div>
                        <div id="Symbols" class="glyphicon glyphicon-remove">Must have at least 1 special character</div>
                    </div>
                    <div className="centre-field-container">
                    <p>
                        <input id="submit" type="submit" value="SUBMIT" />
                    </p>
                    </div>
                </form>
                <div className="auth-errors"></div>
                <div className="auth-successes"></div>
            </section>
        </main>
        <div className="footer-padding"></div>
        <Footer />
        </div>
    )
}