import { getKpisBySession } from "./getKpisBySession";

/**
 * Retrieves the best lap time graph data for a specific event and user.
 *
 * @param {TalentIdSession[]} sessions - The array of sessions.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object used for making API requests.
 * @param {string} token - The JWT token used for authentication.
 * @returns {Promise<Object>} A promise that resolves with the best lap time graph data.
 */
export const getBestLapTimeGraph = async (sessions, userId, ajaxHelper, token) => {
    const points = {};
    for (const session of sessions) {
        const session_kpis = await getKpisBySession(session.id, "BestLap_Session_Rank", ajaxHelper, token);
        for (const kpi of session_kpis) {
            if (!(kpi.userId in points)) {
                points[kpi.userId] = [];
            }
            points[kpi.userId].push([session.name, kpi.metadata.bestLap.lapTime / 1000, kpi.userId === userId]);
        }
    }
    const unwrapped_points = [];
    for (const user in points) {
        unwrapped_points.push(points[user]);
    }
    if (unwrapped_points.length === 0) return null;

    return {
        name: "BestLapTimes",
        displayName: "Best Lap Times",
        description: "This graph shows your fastest lap time from each session in green, and your competitors' fastest lap times in grey.",
        yLabel: "Lap Time",
        xLabel: "Session",
        points: unwrapped_points,
        type: "graphical"
    };
};
