import React, { useEffect, useState } from 'react';
import { useAuth } from '../provider/authProvider';
import { getInsightsData } from '../utils/GetInsightsData';
import NavBar from './NavBar';
import Footer from './Footer';
import down_arrow from '../static/img/down-arrow.png';
import '../static/css/dashboard.css';
import Statistic from './KpiComponents/Statistic';
import { readStoredData } from './DashboardUtils/readStoredData';
import { historicalPlotter, generateAllMetricSelect } from './KpiUtils/historicalPlotter';

/**
 * Insights component
 * 
 * @param {Object} props - The component props.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The rendered Dashboard component.
 */
export default function Insights({ ajaxHelper }) {
    const { token } = useAuth();

    const [insightsData, setInsightsData] = useState(readStoredData("insightsData") || {});
    const [selectedEventNumber, setSelectedEventNumber] = useState(sessionStorage.getItem("selectedEventNumber") || 5);
    const [selectedInsightsMetric, setSelectedInsightsMetric] = useState(sessionStorage.getItem("selectedInsightsMetric") || "BestLapPerformance");

    useEffect(() => {
        if (insightsData) {
            sessionStorage.setItem("insightsData", JSON.stringify(insightsData));
        } else {
            sessionStorage.removeItem("insightsData");
        }
    }, [insightsData])
    useEffect(() => {
        if (selectedEventNumber) {
            sessionStorage.setItem("selectedEventNumber", selectedEventNumber);
        } else {
            sessionStorage.removeItem("selectedEventNumber");
        }
    }, [selectedEventNumber])
    useEffect(() => {
        if (selectedInsightsMetric) {
            sessionStorage.setItem("selectedInsightsMetric", selectedInsightsMetric);
        } else {
            sessionStorage.removeItem("selectedInsightsMetric");
        }
    }, [selectedInsightsMetric])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getInsightsData(token.userId, ajaxHelper, token.token);
                setInsightsData(response);
            } catch (err) {
                console.error(err);
            };
        }
        fetchData();
    }, [token, ajaxHelper])


    function generateEventNumSelect(kpis, max) {
        const eventNumDisplay = document.getElementsByClassName("event-number-display")[0];
        const startingAt = Math.max(max-4, 1);
        eventNumDisplay.innerHTML = "SHOWING 0"+startingAt+"-0"+max+" EVENTS";
        const eventNumSelect = document.getElementById("event-number");
        eventNumSelect.innerHTML = "";
        for (var i = 1; i <= max; i++) {
            const option = document.createElement("option");
            option.value = i;
            option.innerHTML = "0"+i;
            if (i === Math.min(max, 5)) {
                option.selected = true;
            }
            eventNumSelect.appendChild(option);
        }
        eventNumSelect.addEventListener("change", function (event) {
            var eventNumber = this.value;
            if (eventNumber > 0) {
                historicalPlotter(kpis, eventNumber);
                const selectedMetric = document.querySelector(".metric-name .selected");
                if (selectedMetric) {
                    selectedMetric.click();
                }
            }
            const startingAt = Math.max(max-eventNumber+1, 1);
            eventNumDisplay.innerHTML = "SHOWING 0"+startingAt+"-0"+max+" EVENTS";
            setSelectedEventNumber(eventNumber);
        })
    }

    function handleCollapserClick(event) {
        var img = event.target.querySelector("img");
        img.classList.toggle("flipped");
        const content = document.getElementById(img.getAttribute("data-associated"));
        if (content.id === "statistics") {
            if (img.classList.contains("flipped")) {
                content.style.display = "none";
                historicalPlotter(insightsData.historic_kpis, selectedEventNumber);
                const selectedMetric = document.getElementById(selectedInsightsMetric);
                if (selectedMetric) {
                    selectedMetric.click();
                }
            } else {
                content.style.display = "flex";
                historicalPlotter(insightsData.historic_kpis, selectedEventNumber);
                const selectedMetric = document.getElementById(selectedInsightsMetric);
                if (selectedMetric) {
                    selectedMetric.click();
                }
            }
        } else {
            if (img.classList.contains("flipped")) {
                content.style.display = "none";
            } else {
                content.style.display = "block";
                historicalPlotter(insightsData.historic_kpis, selectedEventNumber);
                const selectedMetric = document.getElementById(selectedInsightsMetric);
                if (selectedMetric) {
                    selectedMetric.click();
                }
            }
        }
    
    }

    useEffect(() => {
        if (!insightsData.historic_kpis) return;
        // previousOuterHeight = window.outerHeight;
        // previousOuterWidth = window.outerWidth;
        if (window.innerHeight < 800) window.innerHeight = 800;
        const eventCount = insightsData.event_count;
        const kpis = insightsData.historic_kpis;
        generateAllMetricSelect(kpis, selectedInsightsMetric, setSelectedInsightsMetric);
        generateEventNumSelect(kpis, eventCount);
        var sessionEventNumber = selectedEventNumber;
        if (sessionEventNumber && (sessionEventNumber > 0 && sessionEventNumber <= eventCount)) {
            const eventNumSelect = document.getElementById("event-number");
            eventNumSelect.value = sessionEventNumber;
            eventNumSelect.dispatchEvent(new Event('change'));
            historicalPlotter(kpis, sessionEventNumber);
        } else {
            historicalPlotter(kpis, 5);
        }
        const allMetricButtons = document.querySelectorAll(".metric-name button");
        if (Array.from(allMetricButtons).map(button => button.id).includes(selectedInsightsMetric)) {
            const selectedMetric = document.getElementById(selectedInsightsMetric);
            selectedMetric.click();
        } else {
            allMetricButtons[0].click();
        }
    }, [insightsData])

    const [previousOuterHeight, setPreviousOuterHeight] = useState(window.outerHeight);
    const [previousOuterWidth, setPreviousOuterWidth] = useState(window.outerWidth);
    var doit;
    window.onresize = function (event) {
        clearTimeout(doit);
        doit = setTimeout(() => {
            if (window.location.pathname === "/dashboard") {
                if (window.innerHeight < 600) window.innerHeight = 600;
                if (window.outerHeight === previousOuterHeight && window.outerWidth === previousOuterWidth){
                    setPreviousOuterHeight(window.outerHeight);
                    setPreviousOuterWidth(window.outerWidth);
                    return;
                }
                var sessionSelect = document.getElementById("session");
                if (sessionSelect.value !== "") {
                    var event = new Event('change');
                    sessionSelect.dispatchEvent(event);
                } else {
                    var eventSelect = document.getElementById("event");
                    var event = new Event('change');
                    eventSelect.dispatchEvent(event);
                }
                setPreviousOuterHeight(window.outerHeight);
                setPreviousOuterWidth(window.outerWidth);
            } else if (window.location.pathname === "/kpi") {
                if (window.innerHeight < 600) window.innerHeight = 600;
                if (window.outerHeight === previousOuterHeight && window.outerWidth === previousOuterWidth){
                    setPreviousOuterHeight(window.outerHeight);
                    setPreviousOuterWidth(window.outerWidth);
                    return;
                }
                var eventNumSelect = document.getElementById("event-number");
                var event = new Event('change');
                eventNumSelect.dispatchEvent(event);
                setPreviousOuterHeight(window.outerHeight);
                setPreviousOuterWidth(window.outerWidth);
            }
        }, 500);
    };

    return (
        <div className='dashboard-page'>
            <NavBar />
            <main>
                <div className="stats-wrapper">
                    <div className="insights-title-wrapper" onClick={handleCollapserClick}>
                        <div className="stats-title">DRIVING HISTORY</div>
                        <div className="insights-img-wrapper">
                            <img src={down_arrow} alt="Down arrow" className="insights-img" data-associated="statistics"/>
                        </div>
                    </div>
                    <section id="statistics" className="statistics-container">
                        {
                            insightsData.statistic_kpis &&
                            insightsData.statistic_kpis.map((statistic, index) => {
                                return (
                                    <Statistic key={index} name={statistic.name} value={statistic.value} units={statistic.units} />
                                );
                            })
                        }
                    </section>
                </div>
                <div className="insights-wrapper">
                    <div className="insights-title-wrapper" onClick={handleCollapserClick}>
                        <div className="insights-title">
                            DRIVING INSIGHTS
                        </div>
                        <div className="insights-img-wrapper">
                            <img src={down_arrow} alt="Down arrow" className="insights-img" data-associated="insights"/>
                        </div>
                    </div>
                    <section id="insights" className="insights-container">
                        <div className="inline-containers">
                            <div className="metric-options-container">
                                <div className="event-number-selection">
                                    <label htmlFor="event-number">EVENTS PER GRAPH</label>
                                    <select name="event-number" id="event-number"></select>
                                </div>
                                <div className="metric-options"></div>
                            </div>
                            <div className="insights-chart-container">
                                <div className="insights-chart">
                                    <div className="insights-chart-title">
                                        <div className="insights-chart-name">
                                        </div>
                                        <div className="insights-chart-description">
                                        </div>
                                    </div>
                                    <div className="insights-chart-content">
                                        <div className="insights-chart-plot"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="event-number-selection-container">
                            <div className="event-number-display"></div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
}