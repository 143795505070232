import { TalentIdKpi } from "../models/TalentIdKpi";

/**
 * Retrieves the performance KPIs for a specific user.
 *
 * @param {string} groupIdPrefix - The prefix for the group ID.
 * @param {Object} categoryDict - The dictionary of categories.
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The JWT token used for authentication.
 *
 * @returns {Promise<TalentIdKpi[]>} A promise that resolves with the performance KPIs.
 */
export const getPerformanceKpisByGroup = async (groupIdPrefix, categoryDict, userId, ajaxHelper, token) => {
    const kpis = [];
    const categories = [...new Set(Object.values(categoryDict).flat())];
    for (const category of categories) {
        const group_id = groupIdPrefix + "_" + category;
        const category_kpis_response = await ajaxHelper.get(`/Kpi/byUser/${userId}`, token, { groupId: group_id });
        const category_kpis = category_kpis_response.data.map(kpi => new TalentIdKpi(kpi));
        kpis.push(...category_kpis);
    }
    const ordered_kpis = kpis.sort((a, b) => new Date(a.metadata.timestamp) - new Date(b.metadata.timestamp));
    return ordered_kpis;
};
