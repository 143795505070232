import '../static/css/policy.css';
import Footer from './Footer';
import NavBar from './NavBar';

export default function Cookies() {
    return (
        <div className="policy-page">
            <NavBar />
            <main>
                <div className="privacy-container">
                    <h2>
                        Cookies
                    </h2>
                    <p>
                        Cookies are text files placed on your computer to collect standard internet log information and visitor behaviour information. This information is used to track visitor use of the website and to compile statistical reports on website activity.
                    </p>
                    <p>
                        We use cookies to ensure you get the best experience on our website. Cookies allow us to sign you in and out, and are therefore essential for the website to function. We also use non-essential cookies to track your progress through the website and to remember your preferences.
                    </p>
                    <p>
                        For further information visit <a target="_blank" href="https://www.aboutcookies.org">www.aboutcookies.org</a> or <a target="_blank" href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>
                    </p>
                    <p>
                        You can set your browser not to accept cookies and the above websites tell you how to remove cookies from your browser. However, in a few cases some of our website features may not function as a result.
                    </p>
                    <p>
                        Please also see our <a href="/privacy">Privacy Policy</a>.
                    </p>
                </div>
            </main>
            <Footer />
        </div>
    )
}