import { TalentIdSession } from "./models/TalentIdSession.js";
import { AjaxHelper } from './AjaxHelper.js';
import { TalentIdCompetitor } from "./models/TalentIdCompetitor.js";
import { getKpisBySession } from "./kpi_data/getKpisBySession.js";
import { collatePerformanceScores } from "./kpi_data/collatePerformanceScores.js";
import { getOverviewKpi } from "./kpi_data/getOverviewKpi.js";

/**
 * Retrieves the data for a specific session and user.
 *
 * @param {string} sessionId - The ID of the session.
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object used for making API requests.
 * @param {string} token - The JWT token used for authentication.
 * @returns {Promise<Object>} A promise that resolves with the session data.
 */
export async function getSessionData(sessionId, userId, ajaxHelper, token) {
    const session_response = await ajaxHelper.get(`/Sessions/${sessionId}`, token);
    const session = new TalentIdSession(session_response.data);

    const session_competitors_response = await ajaxHelper.get(`/Competitors/bySession/${sessionId}`, token);
    const session_competitors = session_competitors_response.data.map((competitor) => new TalentIdCompetitor(competitor));

    const session_kpis = [];

    const best_lap_kpis = await getKpisBySession(sessionId, "BestLap_Session_Rank", ajaxHelper, token);
    const final_pace_kpis = await getKpisBySession(sessionId, "Final_Session_Rank", ajaxHelper, token)

    if (best_lap_kpis.length > 0) {
        const best_lap_graph_data = collatePerformanceScores(best_lap_kpis, "BestLap_Session_Rank", "BestLap", userId);
        session_kpis.push(best_lap_graph_data);
    }
    if (final_pace_kpis.length > 0) {
        const final_pace_graph_data = collatePerformanceScores(final_pace_kpis, "Final_Session_Rank", "FinalPace", userId);
        session_kpis.push(final_pace_graph_data);
    }

    const user_best_lap_rank_kpi = best_lap_kpis.find(kpi => kpi.userId === userId);
    if (user_best_lap_rank_kpi) {
        const maximum_ranking = Math.max(...best_lap_kpis.map(kpi => kpi.metadata.ranking));
        const user_best_lap_rank_overview_kpi = getOverviewKpi(user_best_lap_rank_kpi, session.category, maximum_ranking);
        session_kpis.push(user_best_lap_rank_overview_kpi);
    }
    const user_final_pace_rank_kpi = final_pace_kpis.find(kpi => kpi.userId === userId);
    if (user_final_pace_rank_kpi) {
        const maximum_ranking = Math.max(...final_pace_kpis.map(kpi => kpi.metadata.ranking));
        const user_final_pace_rank_overview_kpi = getOverviewKpi(user_final_pace_rank_kpi, session.category, maximum_ranking);
        session_kpis.push(user_final_pace_rank_overview_kpi);
    }

    const user_competitor = session_competitors.find(competitor => competitor.driverId === userId);
    const user_laps = user_competitor ? user_competitor.laps : [];

    const other_driver_competitors = session_competitors.filter(competitor => competitor.driverId !== userId);
    const other_drivers_laps = other_driver_competitors.map(competitor => competitor.laps);

    const numberOfLaps = Math.max(...session_competitors.map(competitor => competitor.laps.map(lap => lap.number)).map(lapNumbers => Math.max(...lapNumbers)));

    return {
        id: session.id,
        name: session.name,
        startDate: session.startDate,
        type: session.type,
        kpis: session_kpis,
        laps: user_laps,
        otherDrivers: other_drivers_laps,
        numberOfLaps: numberOfLaps
    }
}