import { generateMetricSelect } from "./generateMetricSelect";

export function generateAllMetricSelect(data, selectedMetric, setSelectedMetric) {
    const currentMetricButtons = document.querySelectorAll(".metric-name");
    for (var i = 0; i < currentMetricButtons.length; i++) {
        const button = currentMetricButtons[i];
        button.remove();
    }
    // const metricNameContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricNameContainer = document.getElementsByClassName("metric-options")[0];
    data.forEach((metric) => {
        const metricSelect = generateMetricSelect(metric, selectedMetric, setSelectedMetric);
        metricNameContainer.appendChild(metricSelect);
    });
}
