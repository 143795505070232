

export function generateMetricSelect(data, selectedMetric, setSelectedMetric) {
    const metricSelect = document.createElement("div");
    metricSelect.classList.add("metric-name");
    metricSelect.classList.add("event-metric-name");

    const button = document.createElement("button");
    button.classList.add("metric-button");
    button.innerHTML = data[1].toUpperCase();
    button.id = data[0];

    button.addEventListener("click", function (event) {
        const allMetricButtons = document.querySelectorAll(".metric-name .selected");
        for (var i = 0; i < allMetricButtons.length; i++) {
            const button = allMetricButtons[i];
            button.classList.remove("selected");
        }
        const metricName = this.id;
        setSelectedMetric(metricName);
        this.classList.add("selected");
        const allMetricPlots = document.getElementsByClassName("insights-chart");
        for (var i = 0; i < allMetricPlots.length; i++) {
            const plot = allMetricPlots[i];
            if (plot.id === metricName + "-lap-time-chart") {
                plot.style.display = "block";
            } else {
                plot.style.display = "none";
            }
        }
    });
    if (data[0] === selectedMetric) {
        button.click();
        button.classList.add("selected");
    }

    metricSelect.appendChild(button);

    return metricSelect;
}
