import * as d3 from "d3";
// import {event as currentEvent} from 'd3-selection';

export function ordinal_suffix_of(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}

export function plotOverview(data) {
    const section = document.createElement("div");
    section.id = "overview-lap-time-chart";
    section.classList.add("insights-chart");
    section.classList.add("events-insights-chart");
    section.setAttribute("data-type", "overview");

    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = "OVERVIEW";

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");

    const descLine1 = document.createElement("div");
    descLine1.innerHTML = "SINGLE LAP PERFORMANCE SCORE SHOWS YOUR PERFORMANCE OVER A SINGLE LAP, YOUR FASTEST FROM EACH SESSION.";

    const descLine2 = document.createElement("div");
    descLine2.innerHTML = "RACE PERFORMANCE SHOWS YOUR PERFORMANCE OVER A RACE DISTANCE, FOR ALL THE RACE SESSIONS YOU DID AT THIS EVENT.";

    desc.appendChild(descLine1);
    desc.appendChild(descLine2);

    title.appendChild(titleName);
    title.appendChild(desc);

    section.appendChild(title);

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = "overview-info-icon";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.id = "overview-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = "SINGLE LAP PERFORMANCE SCORE SHOWS YOUR PERFORMANCE OVER A SINGLE LAP, YOUR FASTEST FROM EACH SESSION. RACE PERFORMANCE SHOWS YOUR PERFORMANCE OVER A RACE DISTANCE, FOR ALL THE RACE SESSIONS YOU DID AT THIS EVENT.";

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    section.appendChild(informationContainer);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");
    plotContent.classList.add("events-insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");
    plot.classList.add("events-insights-chart-plot");
    plot.classList.add("overview-plot");

    plot.style.width = "100%";

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 30, bottom: 30, left: 60 };
    var width = Math.max(Math.min((wholeContainer.offsetWidth - margin.left - margin.right) / (data.length * 2), 300), 200);
    // const width = 200;
    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const mainPadding = parseInt(window.getComputedStyle(document.getElementsByTagName("main")[0]).paddingTop);
    const sectionTitle = document.getElementsByClassName("insights-title")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight + 62;
    const informationIconHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : 30;
    var graphTitle = 0;
    var height;
    if (window.getComputedStyle(metricOptionsContainer).display === "block") {
        section.style.display = "block";
        graphTitle = title.offsetHeight + 60;
        section.display = "none";
        height = (windowHeight - header - mainPadding - sectionTitle - metricOptionsContainerHeight - informationIconHeight - graphTitle - margin.top - margin.bottom);
    } else {
        height = (windowHeight - header - mainPadding - sectionTitle - metricOptionsContainerHeight - informationIconHeight - graphTitle - margin.top - margin.bottom) / data.length;
    }
    for (let i = 0; i < data.length; i++) {
        const kpi = data[i];
        const displayName = kpi.displayName;
        const rank = kpi.value;
        const maximum_rank = kpi.maximumValue;
        const performancePercentage = 100 / maximum_rank * (maximum_rank - rank + 1);

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        // var radius = Math.min(width, height) / 2
        var radius = width / 2

        // append the svg object to the div called 'my_dataviz'
        var svg = d3.select("#overview-lap-time-chart .insights-chart-plot")
        .append("svg")
            .attr("width", width)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        // Create dummy data
        var pie_data = {
            performancePercentage: performancePercentage,
            other: 100-performancePercentage
        }

        // set the color scale
        var color = d3.scaleOrdinal()
        .domain(["performancePercentage", "other"])
        .range(["rgb(25,240,45)", "rgb(99,99,99)"])

        var arc = d3.arc()
        .innerRadius(radius - 20)         // This is the size of the donut hole
        .outerRadius(radius)


        // Compute the position of each group on the pie:
        var pie = d3.pie()
        .sort(null)
        .startAngle(-90 * (Math.PI/180))
        .endAngle(90 * (Math.PI/180))
        .value(function(d) {return d.value; })
        var transformed_data = Object.entries(pie_data).map(([key, value]) => ({key, value}));
        var data_ready = pie(transformed_data);

        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svg
        .selectAll('whatever')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arc)
        .transition()
        .delay(function(d, i) { return i * 500; })
        .attr('fill', function(d){ return(color(d.data.key)) })
        .duration(800)
        .attrTween('d', function(d) {
            if (d.data.value === 0) {
                return;
            }
            var i = d3.interpolate(d.startAngle+0.1, d.endAngle);
            return function(t) {
                d.endAngle = i(t);
                return arc(d);
            }
        })
        .attr("stroke", "black")
        .style("stroke-width", "2px")
        .style("opacity", 1)

        svg.append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "20px")
        .attr("fill", "white")
        .attr("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .attr("y", 0)
        .text(displayName)

        var rankText = rank + ordinal_suffix_of(rank) + " / " + maximum_rank;

        svg.append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "20px")
        .attr("fill", "white")
        .attr("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .attr("y", -20)
        .text(rankText)
                    
    }
    return ["overview", "Overview"];
}

export function plotPerformanceScores(metricName, performanceData) {

    const section = document.createElement("div");
    section.id = metricName + "-lap-time-chart";
    section.classList.add("insights-chart");
    section.classList.add("events-insights-chart");
    section.setAttribute("data-type", "bar");

    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = "PERFORMANCE SCORES";

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");

    const descLine1 = document.createElement("div");
    descLine1.innerHTML = "THESE GRAPHS SHOW YOUR PERFORMANCE SCORES IN RELATION TO THE OTHER COMPETITORS FOR THE WHOLE EVENT.";

    const descLine2 = document.createElement("div");
    descLine2.innerHTML = "THE HIGHER YOU RANK, THE BETTER YOUR PERFORMANCE RELATIVE TO THE REST OF THE FIELD.";

    desc.appendChild(descLine1);
    desc.appendChild(descLine2);

    title.appendChild(titleName);
    title.appendChild(desc);

    section.appendChild(title);

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = metricName + "-info-icon";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.id = metricName + "-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = "THESE GRAPHS SHOW YOUR PERFORMANCE SCORES IN RELATION TO THE OTHER COMPETITORS FOR THE WHOLE EVENT. THE HIGHER YOU RANK, THE BETTER YOUR PERFORMANCE RELATIVE TO THE REST OF THE FIELD.";

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    section.appendChild(informationContainer);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");
    plotContent.classList.add("events-insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");
    plot.classList.add("events-insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 30, bottom: 70, left: 60 };
    const width = wholeContainer.offsetWidth - margin.left - margin.right;
    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const mainPadding = parseInt(window.getComputedStyle(document.getElementsByTagName("main")[0]).paddingTop);
    const sectionTitle = document.getElementsByClassName("insights-title")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight + 62;
    const informationIconHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : 30;
    var graphTitle = 0;
    if (window.getComputedStyle(metricOptionsContainer).display === "block") {
        section.style.display = "block";
        graphTitle = title.offsetHeight + 100;
        section.display = "none";
    }
    var height;
    if (performanceData.length === 1) {
        height = (windowHeight - header - mainPadding - sectionTitle - metricOptionsContainerHeight - informationIconHeight - graphTitle - margin.top - margin.bottom);
    } else {
        height = (windowHeight - header - mainPadding - sectionTitle - metricOptionsContainerHeight - informationIconHeight - graphTitle - margin.top - margin.bottom) * 0.4;
    }
    for (let i = 0; i < performanceData.length; i++) {
        const data = performanceData[i];
        const points = data.points;
        const xPoints = points.map((point) => point[0]);
        const minimum = 0;
        const maximum = 100;
        const yLabel = data.yLabel;
        const xLabel = data.xLabel;
        const loggedInUserData = points.filter((point) => point[2]);

        // append the SVG object to the body of the page
        var SVG = d3.select("#"+metricName+"-lap-time-chart .insights-chart-plot")
        .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        const x = d3.scaleBand()
            .domain(xPoints)
            .range([0, width])
            .padding(0.2);

        const y = d3.scaleLinear()
            .domain([minimum, maximum])
            .nice()
            .range([height, 0]);

        const xAxisGrid = d3.axisBottom(x).tickSize(-height).tickFormat('').ticks(10);
        const yAxisGrid = d3.axisLeft(y).tickSize(-width).tickFormat('').ticks(10);

        SVG.append('g')
            .attr('class', 'x axis-grid')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxisGrid);
        SVG.append('g')
            .attr('class', 'y axis-grid')
            .call(yAxisGrid);

        var xAxis = SVG.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x).tickFormat((d, i) => {
                const suffix = ordinal_suffix_of(d);
                if (d === loggedInUserData[0][0]) {
                    return d + suffix + " (YOU)";
                }
                return d + suffix;
            }))
        xAxis.selectAll(".tick text")
            .call(wrap, x.bandwidth());
        
        SVG.append("text")
            .attr("y", height + margin.top + margin.bottom / 2)
            .attr("x", width / 2)
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(xLabel);

        var yAxis = SVG.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y));
        SVG.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(yLabel);

        var Tooltip = d3.select("#"+metricName+"-lap-time-chart .insights-chart-plot")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("border-color", "white")
            .style("padding", "5px")
            .style("color", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .style("position", "absolute")
            .style("z-index", "-10");

        var mouseover = function(d) {
            Tooltip
                .style("opacity", 1)
                .style("z-index", 100)
            d3.select(this)
                .style("stroke", "black")
                .style("opacity", 1)
        }
        var mousemove = function(e, d) {
            Tooltip
                .html(d[0] + ordinal_suffix_of(d[0]) + ": " + d[1] + "%")
                .style("left", (e.pageX + 10) + "px")
                .style("top", (e.pageY) + "px")
        }
        var mouseleave = function(d) {
            Tooltip
                .style("opacity", 0)
                .style("z-index", "-10")
            d3.select(this)
                .style("stroke", "none")
        }

        function mousemoveWrapper(e, d) {
            return function() {
                mousemove(e, d);
            };
        }

        points.forEach((point, i) => {
            var uBar = SVG.append('rect')
                .datum(point)
                .attr("x", x(point[0]))
                .attr("y", y(point[1]))
                .attr("width", x.bandwidth())
                .attr("height", height - y(point[1]))
                .attr("fill", function() {return point[2] ? "rgb(25, 240, 45)" : "rgb(99,99,99)" ; })
                .attr("opacity", 1)
                .attr("z-index", 1)

            if (point[2]) {
                uBar.on("mouseover", mouseover)
                    .on("mousemove", function(e) {mousemove(e, e.target.__data__)})
                    .on("mouseleave", mouseleave);
            }            
        });
    }

    return [metricName, "Performance Scores"];

}

export function wrap(text, width) {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + "em").text(word);
      }
    });
  }
  
  export function type(d) {
    d.value = +d.value;
    return d;
  }

export function toggleGraphicalPlotter(data) {
    const name = data.name;
    const displayName = data.displayName;
    const description = data.description;
    const yLabel = data.yLabel;
    const xLabel = data.xLabel;
    const points = data.points;
    const unitMapping = {"seconds": "s", "percentage": "%"}
    const units = Object.keys(points);

    const section = document.createElement("div");
    section.id = name + "-lap-time-chart";
    section.classList.add("insights-chart");
    section.classList.add("events-insights-chart");

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = name + "-info-icon";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.id = name + "-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = description.toUpperCase();

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    section.appendChild(informationContainer);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = displayName.toUpperCase();

    const toggle = document.createElement("div");
    toggle.classList.add("insights-chart-toggle");
    
    const toggleLabel = document.createElement("label");
    toggleLabel.classList.add("toggle");

    const toggleInput = document.createElement("input");
    toggleInput.type = "checkbox";

    const toggleSpan = document.createElement("span");
    toggleSpan.classList.add("slider");

    const toggleSpanOptions = document.createElement("span");
    toggleSpanOptions.classList.add("labels");
    toggleSpanOptions.setAttribute("data-on", unitMapping[units[1]].toUpperCase());
    toggleSpanOptions.setAttribute("data-off", unitMapping[units[0]].toUpperCase());

    toggleLabel.appendChild(toggleInput);
    toggleLabel.appendChild(toggleSpan);
    toggleLabel.appendChild(toggleSpanOptions);

    toggle.appendChild(toggleLabel);

    toggleInput.onclick = function() {
        var selected = this.checked ? units[0] : units[1];
        document.getElementById(name + "-" + units[0]).style.display = selected === units[0] ? "block" : "none";
        document.getElementById(name + "-" + units[1]).style.display = selected === units[1] ? "block" : "none";
    }
    const metricOptionsBanner = document.getElementsByClassName("metric-options")[0];
    if (window.getComputedStyle(metricOptionsBanner).display === "block") {
        titleName.appendChild(toggle);
    } else {
        informationContainer.appendChild(toggle);
    }

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");

    const descLines = description.toUpperCase().split("\n");
    for (let i = 0; i < descLines.length; i++) {
        const descLine = document.createElement("div");
        descLine.innerHTML = descLines[i];
        desc.appendChild(descLine);
    }

    title.appendChild(titleName);
    title.appendChild(desc);

    section.appendChild(title);

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");
    plotContent.classList.add("events-insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");
    plot.classList.add("events-insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 30, bottom: 100, left: 60 };
    const width = wholeContainer.offsetWidth - margin.left - margin.right;

    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const mainPadding = parseInt(window.getComputedStyle(document.getElementsByTagName("main")[0]).paddingTop);
    const sectionTitle = document.getElementsByClassName("insights-title")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight + 62;
    const informationIconHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : 30;
    var graphTitle = 0;
    if (window.getComputedStyle(metricOptionsContainer).display === "block") {
        section.style.display = "block";
        graphTitle = title.offsetHeight + 60;
        section.display = "none";
    }
    const height = (windowHeight - header - mainPadding - sectionTitle - metricOptionsContainerHeight - informationIconHeight - graphTitle - margin.top - margin.bottom);

    var selectedUnit = units[1];

    for (let i = 0; i < units.length; i++) {
        var graphName = name + "-" + units[i];
        const currUnit = units[i];
        const currData = points[currUnit];
        const lengths = currData.map(a=>a.length);
        const longestIndex = lengths.indexOf(Math.max(...lengths));
        const sessionNames = currData[longestIndex].map((point) => point[0]);
        const maximum = d3.max(currData.map((point) => point.map((p) => p[1])).flat(1));
        const minimum = d3.min(currData.map((point) => point.map((p) => p[1])).flat(1));
        const thisXLabel = xLabel;
        const thisYLabel = yLabel + " (" + unitMapping[currUnit] + ")";

        // append the SVG object to the body of the page
        var SVG = d3.select("#"+name+"-lap-time-chart .insights-chart-plot")
        .append("svg")
            .attr("id", graphName)
            .style("display", function() {return currUnit === selectedUnit ? "block" : "none"})
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("id", graphName + "-g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        const x = d3.scaleBand()
            .domain(sessionNames)
            .range([0, width])
            .padding(0.2);

        const y = d3.scaleLinear()
            .domain(minimum < 10 ? [0, maximum + 1] : [minimum - 1, maximum + 1])
            .nice()
            .range([height, 0]);

        const xAxisGrid = d3.axisBottom(x).tickSize(-height).tickFormat('').ticks(10);
        const yAxisGrid = d3.axisLeft(y).tickSize(-width).tickFormat('').ticks(10);

        SVG.append('g')
            .attr('class', 'x axis-grid')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxisGrid);
        SVG.append('g')
            .attr('class', 'y axis-grid')
            .call(yAxisGrid);

        var xAxis = SVG.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")  
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-10) translate(0, 11)");
        SVG.append("text")
            .attr("y", height + margin.top + margin.bottom / 2)
            .attr("x", width / 2)
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(xLabel);

        var yAxis = SVG.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y));
        SVG.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(thisYLabel);

        var clip = SVG.append("defs").append("SVG:clipPath")
            .attr("id", graphName+"-clip")
            .append("SVG:rect")
            .attr("width", width )
            .attr("height", height )
            .attr("x", 0)
            .attr("y", 0);

        const line = d3.line()
            .x((d) => x(d[0]) + x.bandwidth() / 2)
            .y((d) => y(d[1]));

        var rectListener = SVG.append("rect")
            .attr("id", graphName+"-rect")
            .attr("width", width)
            .attr("height", height)
            .style("fill", "none")
            .style("pointer-events", "all")

        var uLine = SVG.append('g')
            .attr("class", "line")
            .attr("clip-path", "url(#"+graphName+"-clip)")
    
        currData.forEach((point, i) => {
            if (point.length < 1) return;
            const isCurrUser = point[0][2];
            uLine.append("path")
                .datum(point)
                .attr("fill", "none")
                .attr("stroke", function() {return isCurrUser ? "rgb(25, 240, 45)" : "white"})
                .attr("stroke-width", function() {return isCurrUser ? 2 : 1})
                .attr("opacity", function() {return isCurrUser ? 1 : 0.4})
                .attr("d", line);
    
            var uScatter = SVG.append('g')
                .attr("class", "scatter")
                .attr("clip-path", "url(#"+graphName+"-clip)")
    
            uScatter
                .selectAll("circle")
                .data(point)
                .enter()
                .append("circle")
                .attr("cx", function (d) { return x(d[0]) + x.bandwidth() / 2; } )
                .attr("cy", function (d) { return y(d[1]); } )
                .attr("r", function() {return isCurrUser ? 5 : 3})
                .style("fill", function() {return isCurrUser ? "rgb(25, 240, 45)" : "white"})
                .style("opacity", function() {return isCurrUser ? 1 : 0.4})
                .style("z-index", function() {return isCurrUser ? 1 : 0});
        });

        var zoom = d3.zoom()
        .scaleExtent([1, 20])  // This control how much you can unzoom (x0.5) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .translateExtent([[0, 0], [width, height]])
        .on("zoom", function(e) {updateChart(e, SVG, yAxis, y, x, width)});

        rectListener.call(zoom);

        document.getElementById(graphName).addEventListener("wheel", preventScroll, {passive: false});
        function preventScroll(e){
            e.preventDefault();
            e.stopPropagation();
        
            return false;
        }

        const currentUserPoints = currData.map((point) => point.map((p) => p[2] ? p[1] : null)).flat(1);
        var userMaximum = d3.max(currentUserPoints);
        var userMinimum = d3.min(currentUserPoints);

        initialZoom();

        function initialZoom() {
            if (userMinimum < 10) {
                var yRange = y(userMaximum + 1) - y(0);
            } else {
                var yRange = y(userMaximum + 1) - y(userMinimum - 1);
            }
            var t = d3.zoomIdentity
                .scale( - height / yRange)
                .translate(0, -y(userMaximum + 1));
            rectListener.call(zoom.transform, t);
        }

        function zoomWrapper(e, SVG, yAxis, y, x, width) {
            return function() {
                updateChart(e, SVG, yAxis, y, x, width);
            };
        }

        function updateChart(e, SVG, yAxis, y, x, width) {
            // recover the new scale
            var newY = e.transform.rescaleY(y);

            const newLine = d3.line()
                .x((d) => x(d[0]) + x.bandwidth() / 2)
                .y((d) => newY(d[1]));

            // update axes with these new boundaries

            // update grid lines
            var newYGrid = d3.axisLeft(newY).tickSize(-width).tickFormat('').ticks(10);
            SVG.selectAll(".y.axis-grid")
                .call(newYGrid);

            yAxis.call(d3.axisLeft(newY))

            // update line position
            SVG.selectAll(".scatter")
            .selectAll("circle")
            .attr('cx', function(d) {return x(d[0]) + x.bandwidth() / 2})
            .attr('cy', function(d) {return newY(d[1])});

            SVG.selectAll(".line")
            .selectAll("path")
            .attr('d', function(d) {return newLine(d)})
        }
    }
    if (toggleInput.checked !== (selectedUnit === units[0])) {
        toggleInput.click();
    }
    return [name, displayName.toUpperCase()];

}

export function graphicalPlotter(data) {
    const name = data.name;
    const displayName = data.displayName;
    const description = data.description;
    const yLabel = data.yLabel;
    const xLabel = data.xLabel;
    const points = data.points;

    const section = document.createElement("div");
    section.id = name + "-lap-time-chart";
    section.classList.add("insights-chart");
    section.classList.add("events-insights-chart");

    const title = document.createElement("div");
    title.id = name + "-title";
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = displayName.toUpperCase();

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");

    const descLines = description.toUpperCase().split("\n");
    for (let i = 0; i < descLines.length; i++) {
        const descLine = document.createElement("div");
        descLine.innerHTML = descLines[i];
        desc.appendChild(descLine);
    }

    title.appendChild(titleName);
    title.appendChild(desc);

    section.appendChild(title);

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = name + "-info-icon";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.id = name + "-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = description.toUpperCase();

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    section.appendChild(informationContainer);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");
    plotContent.classList.add("events-insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");
    plot.classList.add("events-insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 30, bottom: 100, left: 60 };
    const width = wholeContainer.offsetWidth - margin.left - margin.right;
    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const mainPadding = parseInt(window.getComputedStyle(document.getElementsByTagName("main")[0]).paddingTop);
    const sectionTitle = document.getElementsByClassName("insights-title")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight + 62;
    const informationIconHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : 30;
    var graphTitle = 0;
    if (window.getComputedStyle(metricOptionsContainer).display === "block") {
        section.style.display = "block";
        graphTitle = title.offsetHeight + 60;
        section.display = "none";
    }
    const height = (windowHeight - header - mainPadding - sectionTitle - metricOptionsContainerHeight - informationIconHeight - graphTitle - margin.top - margin.bottom);

    // append the SVG object to the body of the page
    var SVG = d3.select("#"+name+"-lap-time-chart .insights-chart-plot")
    .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
    .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    const lengths = points.map(a=>a.length);
    const longestIndex = lengths.indexOf(Math.max(...lengths));
    const sessions = points[longestIndex].map((point) => point[0]);
    const x = d3.scaleBand()
        .domain(sessions)
        .range([0, width])
        .padding(0.2);

    const allPoints = points.map((point) => point.map((p) => p[1])).flat(1);
    const minimum = d3.min(allPoints);
    const maximum = d3.max(allPoints);
    const y = d3.scaleLinear()
        .domain(minimum < 10 ? [0, maximum + 1] : [minimum - 1, maximum + 1])
        .nice()
        .range([height, 0]);

    const xAxisGrid = d3.axisBottom(x).tickSize(-height).tickFormat('').ticks(10);
    const yAxisGrid = d3.axisLeft(y).tickSize(-width).tickFormat('').ticks(10);

    SVG.append('g')
        .attr('class', 'x axis-grid')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxisGrid);
    SVG.append('g')
        .attr('class', 'y axis-grid')
        .call(yAxisGrid);

    var xAxis = SVG.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))
        .selectAll("text")  
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-10) translate(0, 11)");
    SVG.append("text")
        .attr("y", height + margin.top + margin.bottom / 2)
        .attr("x", width / 2)
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .text(xLabel);

    var yAxis = SVG.append("g")
        .attr("class", "axis")
        .call(d3.axisLeft(y));
    SVG.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .text(yLabel + " (s)");

    var clip = SVG.append("defs").append("SVG:clipPath")
        .attr("id", name+"-clip")
        .append("SVG:rect")
        .attr("width", width )
        .attr("height", height )
        .attr("x", 0)
        .attr("y", 0);

    const line = d3.line()
        .x((d) => x(d[0]) + x.bandwidth() / 2)
        .y((d) => y(d[1]));

    var rectListener = SVG.append("rect")
        .attr("width", width)
        .attr("height", height)
        .style("fill", "none")
        .style("pointer-events", "all")

    var uLine = SVG.append('g')
        .attr("class", "line")
        .attr("clip-path", "url(#"+name+"-clip)")

    points.forEach((point, i) => {
        if (point.length < 1) return;
        const isCurrUser = point[0][2];
        uLine.append("path")
            .datum(point)
            .attr("fill", "none")
            .attr("stroke", function() {return isCurrUser ? "rgb(25, 240, 45)" : "white"})
            .attr("stroke-width", function() {return isCurrUser ? 2 : 1})
            .attr("opacity", function() {return isCurrUser ? 1 : 0.4})
            .attr("d", line);

        var uScatter = SVG.append('g')
            .attr("class", "scatter")
            .attr("clip-path", "url(#"+name+"-clip)")

        uScatter
            .selectAll("circle")
            .data(point)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return x(d[0]) + x.bandwidth() / 2; } )
            .attr("cy", function (d) { return y(d[1]); } )
            .attr("r", function() {return isCurrUser ? 5 : 3})
            .style("fill", function() {return isCurrUser ? "rgb(25, 240, 45)" : "white"})
            .style("opacity", function() {return isCurrUser ? 1 : 0.4})
            .style("z-index", function() {return isCurrUser ? 1 : 0});
    });

    var zoom = d3.zoom()
        .scaleExtent([1, 20])  // This control how much you can unzoom (x0.5) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .translateExtent([[0, 0], [width, height]])
        .on("zoom", updateChart);

    rectListener.call(zoom);
    
    document.querySelector("#"+name+"-lap-time-chart .insights-chart-plot svg").addEventListener("wheel", preventScroll, {passive: false});
    function preventScroll(e){
        e.preventDefault();
        e.stopPropagation();
    
        return false;
    }

    const currentUserPoints = points.map((point) => point.map((p) => p[2] ? p[1] : null)).flat(1);
    var userMaximum = d3.max(currentUserPoints);
    var userMinimum = d3.min(currentUserPoints);

    initialZoom();

    function initialZoom() {
        if (userMinimum < 10) {
            var yRange = y(userMaximum + 1) - y(0);
        } else {
            var yRange = y(userMaximum + 1) - y(userMinimum - 1);
        }
        var t = d3.zoomIdentity
            .scale( - height / yRange)
            .translate(0, -y(userMaximum + 1));
        rectListener.call(zoom.transform, t);
    }

    function updateChart(e) {
        // recover the new scale
        var newY = e.transform.rescaleY(y);

        const newLine = d3.line()
            .x((d) => x(d[0]) + x.bandwidth() / 2)
            .y((d) => newY(d[1]));

        // update axes with these new boundaries

        // update grid lines
        var newYGrid = d3.axisLeft(newY).tickSize(-width).tickFormat('').ticks(10);
        SVG.selectAll(".y.axis-grid")
            .call(newYGrid);

        yAxis.call(d3.axisLeft(newY))

        // update line position
        SVG.selectAll(".scatter")
        .selectAll("circle")
        .attr('cx', function(d) {return x(d[0]) + x.bandwidth() / 2})
        .attr('cy', function(d) {return newY(d[1])});

        SVG.selectAll(".line")
        .selectAll("path")
        .attr('d', function(d) {return newLine(d)})
    }

    return [name, displayName.toUpperCase()];
}