export class TalentIdEvent {
    constructor({ id, name, startDate, endDate, trackId, eventType, timingProviderName }) {
        this.id = id;
        this.name = name;
        this.startDate = this.transformDate(startDate);
        this.endDate = this.transformDate(endDate);
        this.trackId = trackId;
        this.eventType = eventType;
        this.timingProviderName = timingProviderName;
    }

    transformDate(date) {
        return new Date(date);
    }
}