export default function validateForm(password, confirm_password, old_password) {
    var upperCaseLetters = /[A-Z]/g;
    var lowerCaseLetters = /[a-z]/g;
    var numbers = /[0-9]/g;
    var specialCharacters = /[!@#$%^&*(),.?":{}|<>-]/g;

    var passwordStrength = {
        "Length": false,
        "UpperCase": false,
        "LowerCase": false,
        "Numbers": false,
        "Symbols": false,
        "Match": false,
        "New": false,
    };

    document.getElementById("password-constraints").style.display = "block";
    
    if (password.length > 8) {
        document.getElementById("Length").className = "glyphicon-ok";
        passwordStrength.Length = true;
    } else {
        document.getElementById("Length").className = "glyphicon-remove";
        passwordStrength.Length = false;
    }
    
    if (password.match(upperCaseLetters)) {
        document.getElementById("UpperCase").className = "glyphicon-ok";
        passwordStrength.UpperCase = true;
    } else {
        document.getElementById("UpperCase").className = "glyphicon-remove";
        passwordStrength.UpperCase = false;
    }
    
    if (password.match(lowerCaseLetters)) {
        document.getElementById("LowerCase").className = "glyphicon-ok";
        passwordStrength.LowerCase = true;
    } else {
        document.getElementById("LowerCase").className = "glyphicon-remove";
        passwordStrength.LowerCase = false;
    }
    
    if (password.match(numbers)) {
        document.getElementById("Numbers").className = "glyphicon-ok";
        passwordStrength.Numbers = true;
    } else {
        document.getElementById("Numbers").className = "glyphicon-remove";
        passwordStrength.Numbers = false;
    }
    
    if (password.match(specialCharacters)) {
        document.getElementById("Symbols").className = "glyphicon-ok";
        passwordStrength.Symbols = true;
    } else {
        document.getElementById("Symbols").className = "glyphicon-remove";
        passwordStrength.Symbols = false;
    }
    
    if (!confirm_password) {
        passwordStrength.Match = true;
    } else {
        if (password === confirm_password) {
            document.getElementById("Match").className = "glyphicon-ok";
            passwordStrength.Match = true;
        } else {
            document.getElementById("Match").className = "glyphicon-remove";
            passwordStrength.Match = false;
        }
    }

    if (password && old_password) {
        if (old_password !== password) {
            document.getElementById("New").className = "glyphicon-ok";
            passwordStrength.New = true;
        } else {
            document.getElementById("New").className = "glyphicon-remove";
            passwordStrength.New = false;
        }
    } else {
        passwordStrength.New = true;
    }


    if (Object.values(passwordStrength).every((value) => value === true)) {
        document.getElementById("submit").disabled = false;
        document.getElementById("submit").style.opacity = 1;
        return true;
    } else {
        document.getElementById("submit").disabled = true;
        document.getElementById("submit").style.opacity = 0.5;
        return false;
    }
}