import * as d3 from "d3";

export function sessionPlotter(data) {
    const numberOfLaps = data.numberOfLaps;
    const userLaps = data.laps;
    const otherDriverLaps = data.otherDrivers;
    const maximum = d3.max([d3.max(userLaps.map((l) => l.lapTime)), d3.max(otherDriverLaps.map((l) => d3.max(l.map((lap) => lap.lapTime))))]) / 1000;
    const minimum = d3.min([d3.min(userLaps.map((l) => l.lapTime)), d3.min(otherDriverLaps.map((l) => d3.min(l.map((lap) => lap.lapTime))))]) / 1000;
    const allLaps = userLaps.map((l) => l.lapTime).concat(otherDriverLaps.map((l) => l.map((lap) => lap.lapTime))).flat();
    const median = d3.median(allLaps) / 1000;
    const mean = d3.mean(allLaps) / 1000;
    const standardDeviation = d3.deviation(allLaps) / 1000;
    const variance = d3.variance(allLaps) / 1000;

    const section = document.createElement("div");
    section.id = "full-lap-time-chart";
    section.classList.add("insights-chart");
    section.classList.add("events-insights-chart");
    section.setAttribute("data", JSON.stringify(data));
    section.setAttribute("data-type", "session-plot");

    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = "Lap Times".toUpperCase();

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");

    const descLine1 = document.createElement("div");
    descLine1.innerHTML = "This chart shows the lap times for each lap of the session.".toUpperCase();

    const descLine2 = document.createElement("div");
    descLine2.innerHTML = "The green line shows your lap times, and the grey lines show the lap times of other drivers.".toUpperCase();

    desc.appendChild(descLine1);
    desc.appendChild(descLine2);

    title.appendChild(titleName);
    title.appendChild(desc);

    section.appendChild(title);

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");
    plotContent.classList.add("events-insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");
    plot.classList.add("events-insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    // set the dimensions and margins of the graph
    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const footer = document.getElementsByTagName("footer")[0].offsetHeight;
    var margin = {top: 10, right: 30, bottom: 60, left: 60},
        width = wholeContainer.offsetWidth - margin.left - margin.right,
        // height = 300 - margin.top - margin.bottom;
        // height = wholeContainer.offsetHeight * 0.8 - margin.top - margin.bottom;
        height = (windowHeight - header - footer - margin.top) * 0.8;

    // append the SVG object to the body of the page
    var SVG = d3.select("#full-lap-time-chart .insights-chart-plot")
    .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
    .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    var x = d3.scaleLinear()
        .domain([1, numberOfLaps])
        .range([ 0, width ]);
    const xAxisTicks = x.ticks()
        .filter(tick => Number.isInteger(tick));

    var y = d3.scaleLinear()
        .domain([minimum-2, maximum+2])
        .range([ height, 0 ]);

    const xAxisGrid = d3.axisBottom(x).tickValues(xAxisTicks).tickSize(-height).tickFormat('').ticks(10);
    const yAxisGrid = d3.axisLeft(y).tickSize(-width).tickFormat('').ticks(10);

    SVG.append('g')
        .attr('class', 'x axis-grid')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxisGrid);
    SVG.append('g')
        .attr('class', 'y axis-grid')
        .call(yAxisGrid);

    var xAxis = SVG.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickValues(xAxisTicks).tickFormat(d3.format('.0f')));
    SVG.append("text")
        .attr("transform", `translate(${width / 2}, ${height + 30})`) // ${height + 25}
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .text("Lap Number")

    var yAxis = SVG.append("g")
        .attr("class", "axis")
        .call(d3.axisLeft(y));
    SVG.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .text("Lap Time (s)");

    var clip = SVG.append("defs").append("SVG:clipPath")
        .attr("id", "clip")
        .append("SVG:rect")
        .attr("width", width + 10 )
        .attr("height", height )
        .attr("x", -5)
        .attr("y", 0);

    const line = d3.line()
        .x((d) => x(d.number))
        .y((d) => y(d.lapTime / 1000));

    var Tooltip = d3.select("#full-lap-time-chart .insights-chart-plot")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("border-color", "white")
        .style("padding", "5px")
        .style("color", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .style("position", "absolute")

      // Three function that change the tooltip when user hover / move / leave a cell

    // var mouseover = function(d) {
    //     Tooltip
    //         .style("opacity", 1)
    //     d3.select(this)
    //         .style("stroke", "black")
    //         .style("opacity", 1)
    // }
    // var mousemove = function(d) {
    //     Tooltip
    //         .html("Lap " + d.number + ", " + d.lapTime / 1000 + " seconds")
    //         .style("left", (d3.mouse(this)[0]+70) + "px")
    //         .style("top", (d3.mouse(this)[1]+90) + "px")
    // }
    // var mouseleave = function(d) {
    //     Tooltip
    //         .style("opacity", 0)
    //     d3.select(this)
    //         .style("stroke", "none")
    // }

    var scatter = SVG.append('g')
        .attr("class", "scatter")
        .attr("clip-path", "url(#clip)")
    
    scatter
        .selectAll("circle")
        .data(userLaps)
        .enter()
        .append("circle")
        .attr("cx", function (d) {return x(d.number);})
        .attr("cy", function (d) {return y(d.lapTime / 1000);})
        .style("fill", "rgb(25, 240, 45)")
        .attr("r", 5)
        // .on("mouseover", mouseover)
        // .on("mousemove", mousemove)
        // .on("mouseleave", mouseleave);

    otherDriverLaps.forEach((compData, i) => {
        var compScatter = SVG.append('g')
            .attr("class", "scatter")
            .attr("clip-path", "url(#clip)")

        var compLine = SVG.append('g')
            .attr("class", "line")
            .attr("clip-path", "url(#clip)")
        
        compLine
            .append("path")
            .datum(compData)
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 1)
            .attr("opacity", 0.4)
            .style("z-index", 10)
            .attr("d", line);

        compScatter
            .selectAll("circle")
            .data(compData)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return x(d.number); } )
            .attr("cy", function (d) { return y(d.lapTime / 1000); } )
            .attr("r", 3)
            .style("fill", "white")
            .style("z-index", 10)
            .style("opacity", 0.4)
    });

    var uLine = SVG.append('g')
        .attr("class", "line")
        .attr("clip-path", "url(#clip)")

    uLine
        .append("path")
        .datum(userLaps)
        .attr("fill", "none")
        .attr("stroke", "rgb(25, 240, 45)")
        .attr("stroke-width", 2)
        .style("z-index", 10)
        .attr("d", line);

    var zoom = d3.zoom()
        .scaleExtent([1, 200])  // This control how much you can unzoom (x0.5) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .translateExtent([[0, 0], [width, height]])
        .on("zoom", updateChart);

    var rectListener = SVG.append("rect")
        .attr("width", width)
        .attr("height", height)
        .style("fill", "none")
        .style("pointer-events", "all")
        // .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .call(zoom);

    document.querySelector("#full-lap-time-chart .insights-chart-plot svg").addEventListener('wheel', preventScroll, {passive: false});
    function preventScroll(e){
        e.preventDefault();
        e.stopPropagation();
    
        return false;
    }
    
    const userMaximum = d3.max(userLaps.map((l) => l.lapTime)) / 1000;
    const userMinimum = d3.min(userLaps.map((l) => l.lapTime)) / 1000;

    initialZoom();

    function initialZoom() {
        var yRange = y(userMaximum + 1) - y(userMinimum - 1);
        var t = d3.zoomIdentity
            .scale( - height / yRange)
            .translate(0, -y(userMaximum + 1));
        // SVG.call(zoom.transform, t);
        rectListener.call(zoom.transform, t);
    }

    function updateChart(e) {
        var newY = e.transform.rescaleY(y);

        var newLine = d3.line()
            .x((d) => x(d.number))
            .y((d) => newY(d.lapTime / 1000));

        var newYGrid = d3.axisLeft(newY).tickSize(-width).tickFormat('').ticks(10);
        SVG.selectAll(".y.axis-grid")
            .call(newYGrid);

        yAxis.call(d3.axisLeft(newY))

        // update line position
        SVG.selectAll(".scatter")
        .selectAll("circle")
        .attr('cx', function(d) {return x(d.number)})
        .attr('cy', function(d) {return newY(d.lapTime / 1000)});

        SVG.selectAll(".line")
        .selectAll("path")
        .attr('d', function(d) {return newLine(d)})
    }

    function resetChart() {
        // Create new scale with default domain
        var newX = d3.scaleLinear()
            .domain([1, numberOfLaps])
            .range([ 0, width ]);
        var newY = d3.scaleLinear()
            .domain([minimum, maximum])
            .range([ height, 0 ]);

        // Update axes with these new boundaries
        xAxis.call(d3.axisBottom(newX))
        yAxis.call(d3.axisLeft(newY))

        // update line position
        SVG.selectAll(".scatter")
        .selectAll("circle")
        .attr('cx', function(d) {return newX(d.number)})
        .attr('cy', function(d) {return newY(d.lapTime / 1000)});

        SVG.selectAll(".line")
        .selectAll("path")
        .attr('d', function(d) {return line(d)})
    }
    return ["full", "LAP TIMES"]
}