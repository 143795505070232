import { useAuth } from '../provider/authProvider';
import '../static/css/bug_report.css';
import Footer from './Footer';
import NavBar from './NavBar';
import { AjaxHelper } from '../utils/AjaxHelper';
import { useNavigate } from 'react-router-dom';

/**
 * Send feedback component for the web application.
 * @param {Object} props - The properties of the component.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The send feedback form component.
 */
export default function SendFeedback({ ajaxHelper }) {

    const { token } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const missing_data = document.getElementById('missing_data').value;
        const additional_data = document.getElementById('additional_data').value;
        const incorrect_data = document.getElementById('incorrect_data').value;
        const website_not_working = document.getElementById('website_not_working').value;
        const missing_functionality = document.getElementById('missing_functionality').value;
        const other = document.getElementById('other').value;
        const comments = document.getElementById('comments').value;

        const data = {
            missing_data,
            additional_data,
            incorrect_data,
            website_not_working,
            missing_functionality,
            other,
            comments
        };
        const message = Object.keys(data).map((key) => {return `${key}: ${data[key]}`}).join("\n");
        try {
            const response = await ajaxHelper.post("/Notifier/support", {
                email: token.email,
                userId: token.userId,
                message
            }, token.token);
            console.log(response);
            alert("Feedback sent successfully.");
            navigate("/");
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="feedback-page">
            <NavBar />
            <main>
                <div>
                    <h3>SEND FEEDBACK</h3>
                    <p>
                        Thanks for taking the time to send feedback. Please fill out the form below and we will get back to you as soon as possible.
                        You can also contact us at <a href="mailto:support@talentid.global">support@talentid.global</a>.
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="grid-inputs">
                        <div className="field-container">
                            <p>
                                <label htmlFor="missing_data">Missing Data</label>
                                <select id="missing_data" name="missing_data" defaultValue="no" required>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="additional_data">Additional Data</label>
                                <select id="additional_data" name="additional_data" defaultValue="no" required>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="incorrect_data">Incorrect Data</label>
                                <select id="incorrect_data" name="incorrect_data" defaultValue="no" required>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="website_not_working">Website Not Working</label>
                                <select id="website_not_working" name="website_not_working" defaultValue="no" required>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="missing_functionality">Missing Functionality</label>
                                <select id="missing_functionality" name="missing_functionality" defaultValue="no" required>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </p>
                        </div>
                        <div className="field-container">
                            <p>
                                <label htmlFor="other">Other</label>
                                <select id="other" name="other" defaultValue="no" required>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className="field-container comments-container">
                        <p>
                                <label htmlFor="comments">Comments</label>
                                <textarea id="comments" name="comments"></textarea>
                            </p>
                    </div>
                    <div className="centre-field-container">
                        <p>
                            <input type="submit" value="SUBMIT" name="submit" id="submit" />
                        </p>
                    </div>
                </form>
            </main>
            <Footer />
        </div>
    )
}