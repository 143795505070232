export default function Statistic({name, units, value}) {
    return (
        <div className="statistic">
            <div className="statistic-name">
                { name.toUpperCase() }
            </div>
            <div className="statistic-value">
                <div className="statistic-numeric">
                    { Math.round(value) }
                </div>
            </div>
            <div className="statistic-units">
                { units }
            </div>
        </div>
    );
}