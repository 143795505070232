import '../static/css/welcome.css';
import Footer from './Footer';
import NavBar from './NavBar';

export default function Welcome() {
    return (
        <div className='welcome-page'>
            <NavBar />
            <div className="first-page">
                <div className="welcome-image"></div>
                <main>
                    <div>
                        <h2 className="welcome-to">WELCOME TO</h2>
                        <h1 className="talent-id-name-welcome">
                            <div className="white">TalentID:</div>
                            <div className="green">COACHING</div>
                        </h1>
                        <h3 className="description">THE NEXT GENERATION OF DRIVER DEVELOPMENT</h3>
                    </div>
                </main>
            </div>
            <div className="footer-padding"></div>
            <Footer />
        </div>
    );
};