import { TalentIdKpi } from "../models/TalentIdKpi";
import { AjaxHelper } from "../AjaxHelper";

/**
 * Retrieves the KPIs for a specific event and group.
 *
 * @param {string} sessionId - The ID of the session.
 * @param {string} groupId - The ID of the group.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The authentication token.
 * @returns {Promise<TalentIdKpi[]>} - A promise that resolves to the response data containing the KPIs.
 */
export const getKpisBySession = async (sessionId, groupId, ajaxHelper, token) => {
    const response = await ajaxHelper.get("/Kpi/bySession/" + sessionId, token, { groupId });
    const kpis = response.data.map(kpi => new TalentIdKpi(kpi));
    return kpis;
};
