import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../components/Login";
import Dashboard from "../components/Dashboard";
import Welcome from "../components/Welcome";
import UserGuide from "../components/UserGuide";
import Account from "../components/Account";
import Insights from "../components/Insights";
import Cookies from "../components/Cookies";
import Privacy from "../components/Privacy";
import SendFeedback from "../components/SendFeedback";
import ChangePassword from "../components/ChangePassword";
import Register from "../components/Register";
import ResetPassword from "../components/ResetPassword";
import ForgotPassword from "../components/ForgotPassword";

const Routes = ({ ajaxHelper }) => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Welcome />,
    },
    {
        path: "/cookies",
        element: <Cookies />,
    },
    {
        path: "/privacy",
        element: <Privacy />,
    }
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/dashboard",
          element: <Dashboard ajaxHelper={ajaxHelper} />,
        },
        {
            path: "/kpi",
            element: <Insights ajaxHelper={ajaxHelper} />,
        },
        {
            path: "/help",
            element: <UserGuide />,
        },
        {
            path: "/account",
            element: <Account />,
        },
        {
            path: "/send-feedback",
            element: <SendFeedback ajaxHelper={ajaxHelper} />
        },
        {
            path: "/change-password",
            element: <ChangePassword ajaxHelper={ajaxHelper} />,
        }
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/login",
      element: <Login ajaxHelper={ajaxHelper} />,
    },
    {
        path: "/register",
        children: [
            {
                path: "/register/:userId/:email",
                element: <Register ajaxHelper={ajaxHelper} />,
            }
        ]
    },
    {
        path: "/reset-password",
        children: [
            {
                path: "/reset-password/:userId/:email",
                element: <ResetPassword ajaxHelper={ajaxHelper} />,
            }
        ]
    },
    {
        path: "/forgot-password",
        children: [
            {
                path: "/forgot-password",
                element: <ForgotPassword ajaxHelper={ajaxHelper} />,
            }
        ]
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;