import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';

import '../static/css/auth.css';
import NavBar from "./NavBar";
import Footer from "./Footer";
import { AjaxHelper } from "../utils/AjaxHelper";
import { useAuth } from "../provider/authProvider";

/**
 * Login component for the web application.
 * @param {Object} props - The properties of the component.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The login form component.
 */
export default function Login({ ajaxHelper }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { setToken } = useAuth();
    const navigate = useNavigate(); 

    /**
     * Handles the form submission.
     * @param {Event} e - The form submit event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const encodedPassword = Buffer.from(password).toString('base64');
        try {
            // Send credentials to API
            const response = await ajaxHelper.post("/Credentials/login", {
                email: username,
                password: encodedPassword
            });
            // Store JWT to local storage
            setToken(response.data);
            // Redirect to home page
            navigate("/");
        } catch(err) {
            // Invalid login 
            const { response } = err;
            if (response) {
                // Display error message
                const message = "Incorrect credentials. Please try again.";
                const authErrors = document.querySelector(".auth-errors");
                authErrors.innerHTML = `<p>${message}</p>`;
            } else {
                // Display generic error message
                const authErrors = document.querySelector(".auth-errors");
                authErrors.innerHTML = "<p>Something went wrong. Please try again later.</p>";
            }
            setToken();
        }
    }

    const handleForgotPasswordSubmit = (e) => {
        e.preventDefault();
        navigate("/forgot-password");
    }

    return (
        <div className="auth-page">
        <NavBar />
        <main>
            <section id="login">
                <form onSubmit={handleSubmit}>
                    <div className="field-container">
                    <p>
                        <label htmlFor="login-username">EMAIL</label>
                        <input id="login-username" value={username} onChange={e => setUsername(e.target.value)} type="email" required />
                    </p>
                    </div>
                    <div className="field-container">
                    <p>
                        <label htmlFor="login-password">PASSWORD</label>
                        <input id="login-password" type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                    </p>
                    </div>
                    <div className="centre-field-container">
                    <p>
                        <input type="submit" value="SIGN IN" />
                    </p>
                    </div>
                </form>
                <div className="auth-errors"></div>
                <div className="auth-successes"></div>
                <form onSubmit={handleForgotPasswordSubmit}>
                    <div className="centre-field-container">
                    <p>
                        <input type="submit" value="FORGOT PASSWORD?" />
                    </p>
                    </div>
                </form>
            </section>
        </main>
        <div className="footer-padding"></div>
        <Footer />
        </div>
    );
}