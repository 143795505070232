import { TalentIdSession } from "../models/TalentIdSession";

/**
 * Retrieves the categories for a user.
 *
 * @param {string} userId - The ID of the user.
 * @param {TalentIdEvent[]} events - The events for the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The JWT token used for authentication.
 *
 * @returns {Promise<Object>} A promise that resolves with the user categories.
 */
export const getUserCategories = async (userId, events, ajaxHelper, token) => {
    const categories = {};
    for (const event of events) {
        const sessions_response = await ajaxHelper.get(`/Sessions/byEvent/${event.id}/byUser/${userId}`, token);
        const sessions = sessions_response.data.map(session => new TalentIdSession(session));
        const event_categories = [...new Set(sessions.map(session => session.category))];
        categories[event.id] = event_categories;
    }
    return categories;
};
