import '../static/css/base.css';
import '../static/css/footer.css';
import talent_id_logo from '../static/img/Talent_ID_Transparent.svg';
import instagram from '../static/img/instagram.png';
import facebook from '../static/img/facebook.png';
import whatsapp from '../static/img/whatsapp.png';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate(); 

    const navigateLink = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    return (
        <footer>
            <div className="grid-container">
                <div className="column left">
                    <a onClick={function(e) {navigateLink(e, "/")}}>
                        <img className="gkl-logo" src={talent_id_logo} alt="TalentID Logo"/>
                    </a>
                    <a href="mailto:support@talentid.global" className="support">
                        <div className="full-contact-email">Contact Us</div>
                        <div className="short-contact-email">Contact Us</div>
                    </a>
                </div>
                <div className="column centre">
                    <a onClick={function(e) {navigateLink(e, "/cookies")}}><div>Cookies</div></a>
                    <a onClick={function(e) {navigateLink(e, "/privacy")}}><div>Privacy Policy</div></a>
                    <a href="https://www.globalkartingleague.com/" target="_blank"><div>©2024 Global Karting League</div></a>
                    <a href="https://www.smedleygroup.com/" target="_blank"><div>©2024 Smedley Group</div></a>
                </div>
                <div className="column right">
                    <div className="socials">
                        <a href="https://www.facebook.com/Globalkartingleague/" target="_blank" className="social-link"><img className="social-img" src={facebook} alt="GKL Facebook"/></a>
                        <a href="https://www.instagram.com/globalkartingleague_uk/" target="_blank" className="social-link"><img className="social-img" src={instagram} alt="GKL Instagram"/></a>
                        <a href="https://wa.me/447746767519" target="_blank" className="social-link"><img className="social-img" src={whatsapp} alt="GKL WhatsApp"/></a>
                    </div>
                    <div className='para'>JOIN OUR COMMUNITY</div>
                </div>
            </div>
        </footer>
    );
}