import Footer from "./Footer";
import NavBar from "./NavBar";

export default function Privacy() {
    return (
        <div className="policy-page">
            <NavBar />
            <main>
                <div className="privacy-container">
                    <h2>
                        Privacy, Terms & Conditions
                    </h2>
                    <h3>
                        What information we collect about you
                    </h3>
                    <p>
                        We collect and process personal data about you when you interact with us. The personal data we process may include, but is not limited to:
                    </p>
                    <ul>
                        <li>your name</li>
                        <li>your work or home address, email address and/or phone number</li>
                        <li>your job title</li>
                        <li>your areas of business interest</li>
                        <li>your username and password</li>
                        <li>information related to the browser or device you use to access our website;</li>
                        <li>internet browser and operating system</li>
                        <li>and/or any other information you provide</li>
                    </ul>
                    <h3>
                        What we use your information for
                    </h3>
                    <p>
                        We may store and use your personal information:
                    </p>
                    <ul>
                        <li>To manage your project and process orders.</li>
                        <li>To personalise our communications to you, our website and products or services for you, in accordance with our legitimate interests.</li>
                        <li>To allow us to communicate with you and provide services appropriate to your needs.</li>
                        <li>To improve our products and services and develop new ones.</li>
                        <li>To monitor use of our websites and online service, to help us check, improve and protect our products, content, services and websites, both online and offline, in accordance with our legitimate interests.</li>
                        <li>To ensure we meet our legal requirements.</li>
                    </ul>
                    <p>
                        Our legitimate interests include our legitimate business purposes and commercial interests in operating our business in a customer-focused, efficient and sustainable manner, in accordance with all applicable legal and regulatory requirements.
                    </p>
                    <h3>
                        Sharing your personal information
                    </h3>
                    <p>
                        SMEDLEY GROUP will not share your information for marketing purposes.
                    </p>
                    <p>
                        We may share your personal data with third parties:
                    </p>
                    <ul>
                        <li>our professional advisors such as our auditors and external legal and financial advisors</li>
                        <li>marketing and communications agencies where they have agreed to process your personal data in line with this Privacy Notice</li>
                        <li>market research companies</li>
                        <li>our suppliers, business partners and sub-contractors search engine and web analytics</li>
                    </ul>
                    <h3>
                        Using your personal data for marketing
                    </h3>
                    <p>
                        We may send you information about products and services which may be of interest to you or the company you work for.
                    </p>
                    <p>
                        You have the right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please email: ross.mcewen@smedleygroup.com
                    </p>
                    <p>
                        You may opt-out of our marketing emails at any time by clicking the ‘unsubscribe’ link at the end of our marketing emails.
                    </p>
                    <p>
                        However, if you tell us you don’t want to receive marketing communications, then you may not hear about products or other services that may be of interest to you.
                    </p>
                    <h3>
                        Access to your information and correction
                    </h3>
                    <p>
                        You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please email or write to us at the address below.
                    </p>
                    <p>
                        We want to make sure that your personal information is accurate and up to date. You may ask us to correct or remove information you think is inaccurate.
                    </p>
                    <h3>
                        How long will we keep your personal data
                    </h3>
                    <p>
                        We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                    </p>
                    <p>
                        To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
                    </p>
                    <h3>
                        Processing outside of the European Economic Area (EEA)
                    </h3>
                    <p>
                        Keeping information about you secure is very important to us so we store and process your personal information in accordance with the high standards required under data protection legislation.
                    </p>
                    <p>
                        The personal data that we collect from you may be transferred to and stored outside the European Economic Area (EEA). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers, in which case the third country’s data protection laws will have been approved as adequate by the European Commission or other applicable safeguards are in place. Further information may be obtained from our Privacy Team.
                    </p>
                    <p>
                        To find out more about how your personal information is protected when it is transferred outside the EEA please contact SMEDLEY MOTORSPORT Ltd using the details below.
                    </p>
                    <h3>
                        Other websites
                    </h3>
                    <p>
                        Our website may contain links to other websites. This privacy policy only applies to our website so when you link to other websites you should read their own privacy policies.
                    </p>
                    <h3>
                        Changes to our privacy policy.
                    </h3>
                    <p>
                        We keep our privacy policy under regular review and we will update as required. This privacy policy was last updated on 1st August 2024.
                    </p>
                    <h3>
                        How to contact us
                    </h3>
                    <p>
                        Please contact us if you have any questions about our privacy policy or information we hold about you.
                    </p>
                    <p>
                        by email: ross.mcewen@smedleygroup.com
                    </p>
                    <h3>
                        Terms & Conditions
                    </h3>
                    <p>
                        SMEDLEY MOTORSPORT Limited provides services to you subject to the following conditions. If you visit our website at www.smedleygroup.com you accept these conditions. Please read them carefully. SMEDLEY MOTORSPORT Limited controls and operates this site from within the UK. The laws of England and Wales govern claims relating to and including the use of this site and materials contained.
                    </p>
                    <p>
                        If you choose to access the site from another country you do so on your own initiative and are responsible for compliance with applicable local lands.
                    </p>
                    <h3>
                        Copyrights
                    </h3>
                    <p>
                        All content included on the site such as text, graphics, logos, button icons, images and audio clips are protected by international copyright laws.
                    </p>
                    <h3>
                        License & Site access
                    </h3>
                    <p>
                        SMEDLEY MOTORSPORT Limited grants you a limited license to access and make personal use of this site. This license does not include any commercial use of this site or its contents, any collection and commercial use of any products, any collection and commercial use of any product listings, descriptions or prices, any derivative use of this site or its contents, any downloading or copying of account information for the benefit of another merchant or any use of data mining, robots or similar data gathering and extraction tools.
                    </p>
                    <p>
                        This site may not be reproduced, duplicated, copied, sold, resold or otherwise exploited without written consent from SMEDLEY MOTORSPORT Limited.
                    </p>
                    <h3>
                        Content
                    </h3>
                    <p>
                        SMEDLEY MOTORSPORT Limited attempts to be as accurate as possible, however, we do not warrant that content is error-free.
                    </p>
                    <p>
                        From time to time there may be information on www.smedleygroup.com that contains typographical errors, inaccuracies or omissions.
                    </p>
                    <p>
                        We reserve the right to correct any errors, inaccuracies or omissions and to change or update information at any time without prior notice. We apologise for any inconvenience this may cause you.
                    </p>
                </div>
            </main>
            <Footer />
        </div>
    )
}