import { AjaxHelper } from "./AjaxHelper";
import { getSessionsByEventByUserByType } from "./kpi_data/getSessionsByEventByUserByType";
import { getKpisByEvent } from "./kpi_data/getKpisByEvent";
import { collatePerformanceScores } from "./kpi_data/collatePerformanceScores";
import { getOverviewKpi } from "./kpi_data/getOverviewKpi";
import { getBestLapTimeGraph } from "./kpi_data/getBestLapTimeGraph";
import { getRacePaceGraph } from "./kpi_data/getRacePaceGraph";
import { gapToFastestGraph } from "./kpi_data/gapToFastestGraph";
import { TalentIdEvent } from "./models/TalentIdEvent";


/**
 * Retrieves the data for a specific event and user.
 *
 * @param {string} eventId - The ID of the event.
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object used for making API requests.
 * @param {string} token - The JWT token used for authentication.
 * @returns {Promise<Object>} A promise that resolves with the event data.
 */
export async function getEventData(eventId, userId, ajaxHelper, token) {
    const eventResponse = await ajaxHelper.get(`/Events/${eventId}`, token);
    const event = new TalentIdEvent(eventResponse.data);

    const raceSessions = await getSessionsByEventByUserByType(eventId, userId, [3,4,5], ajaxHelper, token);
    const sortedRaceSessions = raceSessions.sort((a, b) => a.startDate - b.startDate);
    const sessions = await getSessionsByEventByUserByType(eventId, userId, [], ajaxHelper, token);
    const sortedSessions = sessions.sort((a, b) => a.startDate - b.startDate);
    if (sortedSessions.length === 0) return null;

    const event_kpis = [];
    const category = sortedSessions[0].category;

    const best_lap_group_id = "BestLap_Event_" + category;
    const best_lap_kpis = await getKpisByEvent(eventId, best_lap_group_id, ajaxHelper, token);
    if (best_lap_kpis.length > 0) {
        const best_lap_performance_kpis = collatePerformanceScores(best_lap_kpis, "BestLap_Event", "BestLap", userId);
        event_kpis.push(best_lap_performance_kpis);
    }

    const final_pace_group_id = "FinalPace_Event_" + category;
    const final_pace_kpis = await getKpisByEvent(eventId, final_pace_group_id, ajaxHelper, token);
    if (final_pace_kpis.length > 0) {
        const final_pace_performance_kpis = collatePerformanceScores(final_pace_kpis, "FinalPace_Event", "FinalPace", userId);
        event_kpis.push(final_pace_performance_kpis);
    }

    const user_best_lap_rank_kpi = best_lap_kpis.find(kpi => kpi.userId === userId);
    if (user_best_lap_rank_kpi) {
        const maximum_ranking = Math.max(...best_lap_kpis.map(kpi => kpi.metadata.ranking));
        const user_best_lap_rank_overview_kpi = getOverviewKpi(user_best_lap_rank_kpi, category, maximum_ranking);
        event_kpis.push(user_best_lap_rank_overview_kpi);
    }

    const user_final_pace_rank_kpi = final_pace_kpis.find(kpi => kpi.userId === userId);
    if (user_final_pace_rank_kpi) {
        const maximum_ranking = Math.max(...final_pace_kpis.map(kpi => kpi.metadata.ranking));
        const user_final_pace_rank_overview_kpi = getOverviewKpi(user_final_pace_rank_kpi, category, maximum_ranking);
        event_kpis.push(user_final_pace_rank_overview_kpi);
    }

    const best_lap_time_graph = await getBestLapTimeGraph(sortedSessions, userId, ajaxHelper, token);
    if (best_lap_time_graph) {
        event_kpis.push(best_lap_time_graph);
    }

    const race_pace_graph = await getRacePaceGraph(sortedRaceSessions, userId, ajaxHelper, token);
    if (race_pace_graph) {
        event_kpis.push(race_pace_graph);
    }

    const gap_to_fastest_graph = await gapToFastestGraph(sortedSessions, userId, ajaxHelper, token);
    if (gap_to_fastest_graph) {
        event_kpis.push(gap_to_fastest_graph);
    }

    return {
        id: event.id,
        name: event.name,
        startDate: event.startDate,
        endDate: event.endDate,
        raceSessions: sortedRaceSessions,
        sessions: sortedSessions,
        kpis: event_kpis
    }
}