import React, { useEffect, useState } from "react";

import { AjaxHelper } from "../utils/AjaxHelper";
import { useAuth } from "../provider/authProvider";
import NavBar from "./NavBar";
import Footer from "./Footer";

import '../static/css/dashboard.css';

import { TalentIdEvent } from "../utils/models/TalentIdEvent";
import { TalentIdSession } from "../utils/models/TalentIdSession";
import { getEventData } from "../utils/GetEventData";
import { getSessionData } from "../utils/GetSessionData";
import { graphicalPlotter, plotOverview, plotPerformanceScores, toggleGraphicalPlotter } from "./DashboardUtils/eventPlotters";
import { sessionPlotter } from "./DashboardUtils/sessionPlotter";
import { readStoredData } from "./DashboardUtils/readStoredData";
import { generateAllMetricSelect } from "./DashboardUtils/generateAllMetricSelect";
import { useNavigate } from "react-router-dom";


/**
 * Dashboard component.
 * 
 * @param {Object} props - The component props.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The rendered Dashboard component.
 */
export default function Dashboard({ ajaxHelper }) {
    const [events, setEvents] = useState(readStoredData("events") || []);
    const [sessions, setSessions] = useState(readStoredData("sessions") || []);
    const [eventData, setEventData] = useState(readStoredData("eventData") || {});
    const [sessionData, setSessionData] = useState(readStoredData("sessionData") || {});
    const [selectedMetric, setSelectedMetric] = useState(sessionStorage.getItem("selectedMetric") || "overview");

    const { token, setToken } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (events) {
            sessionStorage.setItem("events", JSON.stringify(events));
        } else {
            sessionStorage.removeItem("events");
        }
    }, [events]);
    useEffect(() => {
        if (sessions) {
            sessionStorage.setItem("sessions", JSON.stringify(sessions));
        } else {
            sessionStorage.removeItem("sessions");
        }
    }, [sessions]);
    useEffect(() => {
        if (eventData) {
            sessionStorage.setItem("eventData", JSON.stringify(eventData));
        } else {
            sessionStorage.removeItem("eventData");
        }
    }, [eventData]);
    useEffect(() => {
        if (sessionData) {
            sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
        } else {
            sessionStorage.removeItem("sessionData");
        }
    }, [sessionData]);
    useEffect(() => {
        if (selectedMetric) {
            sessionStorage.setItem("selectedMetric", selectedMetric);
        } else {
            sessionStorage.removeItem("selectedMetric");
        }
    }, [selectedMetric]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await ajaxHelper.get("/Events/byUser/" + token.userId, token.token);
                const events = response.data.map((event) => {
                    return new TalentIdEvent(event);
                });
                const sortedEvents = events.sort((a, b) => {
                    return b.startDate - a.startDate;
                });
                setEvents(sortedEvents);
            } catch(err) {
                // if error is the ajax helper error returning a 401 status then redirect to login
                if (err.response.status === 401) {
                    setToken();
                    sessionStorage.clear();
                    navigate("/");
                }
                console.error(err);
            }
        }
        fetchEvents();
    }, [ajaxHelper, token.userId, token.token]);

    const handleEventChange = async (event) => {
        const eventId = event.target.value;
        if (!eventId) {
            return;
        }
        setSessions([]);
        setEventData({});
        setSessionData({});
        const fetchSessions = async () => {
            try {
                const response = await ajaxHelper.get("/Sessions/byEvent/" + eventId + "/byUser/" + token.userId, token.token);
                const sessionsResponse = response.data.map((session) => {
                    return new TalentIdSession(session);
                });
                const sortedSessions = sessionsResponse.sort((a, b) => {
                    return a.startDate - b.startDate;
                });
                setSessions(sortedSessions);
            } catch (err) {
                // if error is the ajax helper error returning a 401 status then redirect to login
                if (err.response.status === 401) {
                    setToken();
                    sessionStorage.clear();
                    navigate("/");
                }
                console.error(err);
            }
        }
        await fetchSessions();
        const fetchEventData = async () => {
            const data = await getEventData(eventId, token.userId, ajaxHelper, token.token);
            setEventData(data);
        }
        await fetchEventData();
    }

    const handleSessionChange = async (event) => {
        const sessionId = event.target.value;
        if (!sessionId) {
            return;
        }
        setSessionData({});
        const fetchSessionData = async () => {
            const data = await getSessionData(sessionId, token.userId, ajaxHelper, token.token);
            setSessionData(data);
        }
        await fetchSessionData();
    }

    const handleClear = () => {
        document.getElementById("session").value = "";
        setSessionData({});
    }

    const [previousOuterHeight, setPreviousOuterHeight] = useState(window.outerHeight);
    const [previousOuterWidth, setPreviousOuterWidth] = useState(window.outerWidth);
    var doit;
    window.onresize = function (event) {
        clearTimeout(doit);
        doit = setTimeout(() => {
            if (window.location.pathname === "/dashboard") {
                if (window.innerHeight < 600) window.innerHeight = 600;
                if (window.outerHeight === previousOuterHeight && window.outerWidth === previousOuterWidth){
                    setPreviousOuterHeight(window.outerHeight);
                    setPreviousOuterWidth(window.outerWidth);
                    return;
                }
                var sessionSelect = document.getElementById("session");
                if (sessionSelect.value !== "") {
                    handleSessionChange({ target: { value: sessionSelect.value } });
                } else {
                    var eventSelect = document.getElementById("event");
                    handleEventChange({ target: { value: eventSelect.value } });
                }
                setPreviousOuterHeight(window.outerHeight);
                setPreviousOuterWidth(window.outerWidth);
            } else if (window.location.pathname === "/kpi") {
                if (window.innerHeight < 600) window.innerHeight = 600;
                if (window.outerHeight === previousOuterHeight && window.outerWidth === previousOuterWidth){
                    setPreviousOuterHeight(window.outerHeight);
                    setPreviousOuterWidth(window.outerWidth);
                    return;
                }
                var eventNumSelect = document.getElementById("event-number");
                var event = new Event('change');
                eventNumSelect.dispatchEvent(event);
                setPreviousOuterHeight(window.outerHeight);
                setPreviousOuterWidth(window.outerWidth);
            }
        }, 500);
    };

    useEffect(() => {
        if (sessionData.kpis) {
            document.getElementById("session").value = sessionData.id;
            const allMetricNames = [];
            document.getElementsByClassName("insights-chart-container")[0].innerHTML = "";
            let ret = plotOverview(sessionData.kpis.filter((kpi) => kpi.type === "ranking"));
            if (ret) allMetricNames.push(ret);
            ret = plotPerformanceScores("performanceScores", sessionData.kpis.filter((kpi) => kpi.type === "performance"));
            if (ret) allMetricNames.push(ret);
            const graphicalKpis = sessionData.kpis.filter((kpi) => kpi.type === "graphical");
            graphicalKpis.forEach((kpi) => {
                ret = graphicalPlotter(kpi);
                if (ret) allMetricNames.push(ret);
            })
            const toggleableKpis = sessionData.kpis.filter((kpi) => kpi.type === "toggleableGraphical");
            toggleableKpis.forEach((kpi) => {
                ret = toggleGraphicalPlotter(kpi);
                if (ret) allMetricNames.push(ret);
            })
            ret = sessionPlotter(sessionData);
            if (ret) allMetricNames.push(ret);
            if (!(allMetricNames.map((metric) => metric[0]).includes(selectedMetric))) {
                generateAllMetricSelect(allMetricNames, "overview", setSelectedMetric);
                setSelectedMetric("overview");
            } else {
                generateAllMetricSelect(allMetricNames, selectedMetric, setSelectedMetric);
            }
        }
        else if (eventData.kpis && !sessionData.kpis) {
            document.getElementById("session").value = "";
            document.getElementById("event").value = eventData.id;
            const allMetricNames = [];
            document.getElementsByClassName("insights-chart-container")[0].innerHTML = "";
            let ret = plotOverview(eventData.kpis.filter((kpi) => kpi.type === "ranking"));
            if (ret) allMetricNames.push(ret);
            ret = plotPerformanceScores("performanceScores", eventData.kpis.filter((kpi) => kpi.type === "performance"));
            if (ret) allMetricNames.push(ret);
            const graphicalKpis = eventData.kpis.filter((kpi) => kpi.type === "graphical");
            graphicalKpis.forEach((kpi) => {
                ret = graphicalPlotter(kpi);
                if (ret) allMetricNames.push(ret);
            })
            const toggleableKpis = eventData.kpis.filter((kpi) => kpi.type === "toggleableGraphical");
            toggleableKpis.forEach((kpi) => {
                ret = toggleGraphicalPlotter(kpi);
                if (ret) allMetricNames.push(ret);
            })
            if (!(allMetricNames.map((metric) => metric[0]).includes(selectedMetric))) {
                generateAllMetricSelect(allMetricNames, "overview", setSelectedMetric);
                setSelectedMetric("overview");
            } else {
                generateAllMetricSelect(allMetricNames, selectedMetric, setSelectedMetric);
            }
        }
    }, [eventData, sessionData])

    useEffect(() => {
        if ((eventData.kpis && document.getElementById("session").value !== "" && !(sessionData.kpis) && document.getElementsByClassName("loading-text").length === 0) || (!(eventData.kpis) && document.getElementsByClassName("loading-text").length === 0)) {
            // SHOW LOADING TEXT
            document.getElementsByClassName("insights-chart-container")[0].innerHTML = "";
            var loadingText = document.createElement("div");
            loadingText.classList.add("loading-text");
            loadingText.innerHTML = "Loading...";
            document.getElementsByClassName("insights-chart-container")[0].appendChild(loadingText);
        } else {
            // HIDE LOADING TEXT
            var loadingText = document.getElementsByClassName("loading-text")[0];
            if (loadingText) {
                loadingText.remove();
            }
        }
    }, [eventData, sessionData])

    useEffect(() => {
        if (events.length > 0 && events.map((event) => event.id).includes(eventData.id)) {
            document.getElementById("event").value = eventData.id;
        } else if (events.length > 0) {
            const eventSelect = document.getElementById("event");
            eventSelect.value = events[0].id;
            handleEventChange({ target: { value: eventSelect.value } });
        }
    }, [events])
    useEffect(() => {
        if (sessions.length > 0 && sessions.map((session) => session.id).includes(sessionData.id)) {
            document.getElementById("session").value = sessionData.id;
        }
    }, [sessions])

    return (
        <div className="dashboard-page">
            <NavBar />
            <main>
                <div id="data" hidden>
                    <div id="all" data-all="{{ all_data }}"></div>
                </div>

                <div className="insights-wrapper">
                    <div className="insights-title" style={{borderBottom: "2px rgb(25, 240, 45) solid"}}>
                        DRIVING INSIGHTS
                    </div>
                    <section id="insights" className="insights-container events-insights-container">
                        <div className="inline-containers events-inline-containers">
                            <div className="metric-options-container events-metric-options-container">
                                <select name="event" id="event" className="metric-select" onChange={handleEventChange} defaultValue={eventData.id || ""}>
                                    <option value="" disabled>Select your event</option>
                                    {events.map((event, index) => {
                                        return <option key={index} value={event.id}>{event.name} '{event.startDate.getFullYear().toString().substr(-2)}</option>
                                    })}
                                </select>
                                {
                                    sessions.length > 0 && 
                                    <select name="session" id="session" className="metric-select" defaultValue={sessionData.id || ""} onChange={handleSessionChange}>
                                        <option value="" disabled>Select your session</option>
                                        {sessions.map((session, index) => {
                                            return <option key={index} value={session.id}>{session.name}</option>
                                        })}
                                    </select>   
                                }
                                {
                                    Object.keys(sessionData).length > 0 &&
                                    <button id="clear" className="metric-button" onClick={handleClear}>Back to event</button>
                                }
                                <div className="metric-options">
                                </div>
                            </div>
                            <div className="insights-chart-container events-insights-chart-container">
                                <div className="insights-chart">
                                    <div className="insights-chart-title">
                                        <div className="insights-chart-name">
                                        </div>
                                        <div className="insights-chart-description">
                                        </div>
                                    </div>
                                    <div className="insights-chart-content">
                                        <div className="insights-chart-plot"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
}