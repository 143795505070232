import { useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import '../static/css/auth.css';
import { AjaxHelper } from "../utils/AjaxHelper";

/**
 * ForgotPassword component for the web application.
 * 
 * @param {Object} props - The properties of the component.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The forgot password form component.
 */
export default function ForgotPassword({ ajaxHelper }) {
    const [username, setUsername] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send email to API
            const response = await ajaxHelper.post("/Credentials/forgotPassword", {
                email: username
            });
            // Display success message
            const authSuccesses = document.querySelector(".auth-successes");
            authSuccesses.innerHTML = "<p>If a credential with this email exists, a reset password email has been sent. Please check your inbox.</p>";
        } catch(err) {
            // Display error message
            const authErrors = document.querySelector(".auth-errors");
            authErrors.innerHTML = "<p>Something went wrong. Please try again later.</p>";
        }
    }

    return (
        <div className="auth-page">
        <NavBar />
        <main>
            <section id="forgot-password">
                <form onSubmit={handleSubmit}>
                    <div className="field-container">
                    <p>
                        <label htmlFor="login-username">EMAIL</label>
                        <input id="login-username" value={username} onChange={e => setUsername(e.target.value)} type="email" required />
                    </p>
                    </div>
                    <div className="centre-field-container">
                    <p>
                        <input type="submit" value="REQUEST RESET" />
                    </p>
                    </div>
                </form>
                <div className="auth-errors"></div>
                <div className="auth-successes"></div>
            </section>
        </main>
        <div className="footer-padding"></div>
        <Footer />
        </div>
    );
}