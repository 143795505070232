import { TalentIdKpi } from "../models/TalentIdKpi";

/**
 * Retrieves the gap to the fastest KPIs for a specific user.
 *
 * @param {TalentIdEvent[]} events - The events for the user.
 * @param {Object} categoryDict - The dictionary of categories.
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The JWT token used for authentication.
 *
 * @returns {Promise<Object>} A promise that resolves with the gap to the fastest KPIs.
 */
export const getGapToFastestKpis = async (events, categoryDict, userId, ajaxHelper, token) => {
    const all_event_gaps = {};
    for (const event of events) {
        all_event_gaps[event.id] = [];
    };
    const categories = [...new Set(Object.values(categoryDict).flat())];
    for (const category of categories) {
        const group_id = "BestLap_Event_" + category;
        const category_kpis_response = await ajaxHelper.get(`/Kpi/byUser/${userId}`, token, { groupId: group_id });
        const category_kpis = category_kpis_response.data.map(kpi => new TalentIdKpi(kpi)).sort((a, b) => new Date(a.metadata.timestamp) - new Date(b.metadata.timestamp));
        for (const kpi of category_kpis) {
            const session_id = kpi.metadata.bestLap.sessionId;
            const session_kpis_response = await ajaxHelper.get(`/Kpi/bySession/${session_id}`, token, { groupId: "BestLap_Session_Rank" });
            const session_kpis = session_kpis_response.data.map(kpi => new TalentIdKpi(kpi)).sort((a, b) => a.metadata.bestLap.lapTime - b.metadata.bestLap.lapTime);
            if (session_kpis.length === 0) continue;
            const fastest_lap_time = session_kpis[0].metadata.bestLap.lapTime;
            const lap_time = kpi.metadata.bestLap.lapTime;
            const gap_seconds = (lap_time - fastest_lap_time) / 1000;
            const gap_percentage = (lap_time - fastest_lap_time) / fastest_lap_time * 100 
            all_event_gaps[kpi.eventId].push({
                label: kpi.metadata.label,
                gapSeconds: gap_seconds,
                gapPercentage: gap_percentage,
                timestamp: kpi.metadata.timestamp,
            });
        }
    }
    return all_event_gaps;
};
