import { AjaxHelper } from "./AjaxHelper";
import { getUserCategories } from "./kpi_data/getUserCategories";
import { getStatistic } from "./kpi_data/getStatistic";
import { TalentIdEvent } from "./models/TalentIdEvent";
import { getPerformanceKpisByGroup } from "./kpi_data/getPerformanceKpisByGroup";
import { getPerformanceGraph } from "./kpi_data/getPerformanceGraph";
import { graph_map } from "./kpi_data/graph_map";
import { getAveragePerformance } from "./kpi_data/getAveragePerformance";
import { getRankingGraph } from "./kpi_data/getRankingGraph";
import { getGapToFastestKpis } from "./kpi_data/getGapToFastestKpis";
import { getGapToFastestGraph } from "./kpi_data/getGapToFastestGraph";

/**
 * Retrieves the data for a specific user.
 * 
 * @param {string} userId - The ID of the user.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The JWT token used for authentication.
 * 
 * @returns {Promise<Object>} A promise that resolves with the user data.
 */
export async function getInsightsData(userId, ajaxHelper, token) {
    const lap_count = await getStatistic("Laps_Completed", "Total Laps", [0,1,2,3,4,5], userId, ajaxHelper, token, "laps");
    const race_lap_count = await getStatistic("Laps_Completed", "Race Laps", [3,4], userId, ajaxHelper, token, "laps")
    const minutes_driven = await getStatistic("Laps_Total_Time", "Total Time", [0,1,2,3,4,5], userId, ajaxHelper, token,  "minutes", 1/60000)
    const race_minutes_driven = await getStatistic("Laps_Total_Time", "Race Time", [3,4], userId, ajaxHelper, token,  "minutes", 1/60000)
    const distance_driven = await getStatistic("Laps_Distance_Completed", "Total Distance", [0,1,2,3,4,5], userId, ajaxHelper, token,  "km", 1/1000)
    const race_distance_driven = await getStatistic("Laps_Distance_Completed", "Race Distance", [3,4], userId, ajaxHelper, token,  "km", 1/1000)

    const statistic_kpis = [lap_count, race_lap_count, minutes_driven, race_minutes_driven, distance_driven, race_distance_driven].filter(kpi => kpi !== null);

    const events_response = await ajaxHelper.get(`/Events/byUser/${userId}`, token);
    const events = events_response.data.map(event => new TalentIdEvent(event));
    const sorted_events = events.sort((a, b) => {
        return b.startDate - a.startDate;
    });

    const categories = await getUserCategories(userId, sorted_events, ajaxHelper, token);

    const historic_kpis = [];

    const best_lap_kpis = await getPerformanceKpisByGroup("BestLap_Event", categories, userId, ajaxHelper, token);
    if (best_lap_kpis.length > 0) {
        const best_lap_performance_graph = await getPerformanceGraph(best_lap_kpis, graph_map.BestLap_Event);
        historic_kpis.push(best_lap_performance_graph);
        const average_single_lap_performance = getAveragePerformance(best_lap_kpis, "Average Single Lap Performance");
        statistic_kpis.push(average_single_lap_performance);
        const single_lap_ranking_graph = getRankingGraph(best_lap_kpis, graph_map.BestLapRanking);
        historic_kpis.push(single_lap_ranking_graph);
    }

    const final_pace_kpis = await getPerformanceKpisByGroup("FinalPace_Event", categories, userId, ajaxHelper, token);
    if (final_pace_kpis.length > 0) {
        const final_pace_performance_graph = await getPerformanceGraph(final_pace_kpis, graph_map.FinalPace_Event);
        historic_kpis.push(final_pace_performance_graph)
        const average_final_pace_performance = getAveragePerformance(final_pace_kpis, "Average Race Performance");
        statistic_kpis.push(average_final_pace_performance)
        const race_ranking_graph = getRankingGraph(final_pace_kpis, graph_map.FinalPaceRanking);
        historic_kpis.push(race_ranking_graph)
    }

    const gap_to_fastest_kpis = await getGapToFastestKpis(sorted_events, categories, userId, ajaxHelper, token);
    if (Object.keys(gap_to_fastest_kpis).length > 0) {
        const gap_to_fastest_graph = getGapToFastestGraph(gap_to_fastest_kpis);
        historic_kpis.push(gap_to_fastest_graph);
    }

    return {
        statistic_kpis,
        sorted_events,
        categories,
        historic_kpis,
        event_count: sorted_events.length
    };
}