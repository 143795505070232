import React, { useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';
import '../static/css/auth.css';
import { useAuth } from "../provider/authProvider";
import validateForm from "./AuthUtils/passwordValidation";

/**
 * Login component for the web application.
 * @param {Object} props - The properties of the component.
 * @param {AjaxHelper} props.ajaxHelper - The AJAX helper object.
 * @returns {JSX.Element} The login form component.
 */
export default function ChangePassword({ ajaxHelper }) {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const { token } = useAuth();
    const navigate = useNavigate(); 

    /**
     * Handles the form submission.
     * @param {Event} e - The form submit event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const encodedOldPassword = Buffer.from(oldPassword).toString('base64');
        const encodedNewPassword = Buffer.from(newPassword).toString('base64');
        try {
            // Send credentials to API
            const response = await ajaxHelper.post("/Credentials/changePassword", {
                userId: token.userId,
                email: token.email,
                oldPassword: encodedOldPassword,
                newPassword: encodedNewPassword,
            }, token.token);
            console.log(response);
            // Redirect to home page
            alert("Password changed successfully.");
            navigate("/");
        } catch(err) {
            // Invalid login 
            const { response } = err;
            if (response) {
                // Display error message
                const message = "Incorrect credentials. Please try again.";
                const authErrors = document.querySelector(".auth-errors");
                authErrors.innerHTML = `<p>${message}</p>`;
            } else {
                // Display generic error message
                const authErrors = document.querySelector(".auth-errors");
                authErrors.innerHTML = "<p>Something went wrong. Please try again later.</p>";
            }
        }
    }

    return (
        <div className="auth-page">
        <NavBar />
        <main>
            <section id="change-password">
                <form onSubmit={handleSubmit}>
                <div className="field-container">
                    <p>
                        <label htmlFor="old-password">OLD PASSWORD</label>
                        <input id="old-password" type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)} required />
                    </p>
                    </div>
                    <div className="field-container">
                    <p>
                        <label htmlFor="new-password">NEW PASSWORD</label>
                        <input id="new-password" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} onKeyUp={function(e) {
                            return validateForm(e.target.value, document.getElementById("confirm-password").value, document.getElementById("old-password").value);
                        }} required />
                    </p>
                    </div>
                    <div className="field-container">
                    <p>
                        <label htmlFor="confirm-password">CONFIRM PASSWORD</label>
                        <input id="confirm-password" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} onKeyUp={function(e) {
                            return validateForm(document.getElementById("new-password").value, e.target.value, document.getElementById("old-password").value);
                        }} required />
                    </p>
                    </div>
                    <div class="password-constraints-centred" id="password-constraints" style={{display: "none"}}>
                        <div id="Length" class="glyphicon glyphicon-remove">Must be longer than 8 charcters</div>
                        <div id="UpperCase" class="glyphicon glyphicon-remove">Must have at least 1 upper case character</div>
                        <div id="LowerCase" class="glyphicon glyphicon-remove">Must have at least 1 lower case character</div>
                        <div id="Numbers" class="glyphicon glyphicon-remove">Must have at least 1 numeric character</div>
                        <div id="Symbols" class="glyphicon glyphicon-remove">Must have at least 1 special character</div>
                        <div id="Match" class="glyphicon glyphicon-remove">Passwords must match</div>
                        <div id="New" class="glyphicon glyphicon-remove">Your new password must be different to your old</div>
                    </div>
                    <div className="centre-field-container">
                    <p>
                        <input id="submit" type="submit" value="SUBMIT" />
                    </p>
                    </div>
                </form>
                <div className="auth-errors"></div>
                <div className="auth-successes"></div>
            </section>
        </main>
        <div className="footer-padding"></div>
        <Footer />
        </div>
    );
}