export class TalentIdKpi {
    constructor({ id, eventId, sessionId, userId, type, metadata, groupId }) {
        this.id = id;
        this.eventId = eventId;
        this.sessionId = sessionId;
        this.userId = userId;
        this.type = type;
        this.metadata = metadata;
        this.groupId = groupId;
    }
}