import Footer from "./Footer";
import NavBar from "./NavBar";

import '../static/css/help.css';

export default function UserGuide() {
    return (
        <div className="help-page">
            <NavBar />
            <main>
                <div className="help-container">
                    <section className="help-section">
                        <h4>EVENT OVERVIEW</h4>
                        <p style={{marginBottom: 0}}>The Event Overview page is your gateway to in-depth analysis of your karting performance. You are able to select an event from all that you have participated in, and within those events the individual sessions in which you participated. Here's what each metric means:</p>
                        <ul>
                            <li><strong>Overview:</strong> Your ranking in the session or event, based on your single lap and race scores.</li>
                            <li><strong>Race Performance (%):</strong> A percentage score of your overall race performance.</li>
                            <li><strong>Single Lap Performance (%):</strong> A percentage score of how well you performed on a single lap.</li>
                            <li><strong>Best Lap Times:</strong> Compare your best lap times with other drivers in the session.</li>
                            <li><strong>Race Pace:</strong> Evaluate your average lap times against other participants.</li>
                            <li><strong>Gap to Best Lap Times:</strong> Understand your gap to the fastest lap in each session.</li>
                            <li><strong>Lap Times:</strong> Visualize all your lap times for a selected session.</li>
                        </ul>
                    </section>

                    <section className="help-section">
                        <h4>INSIGHTS</h4>
                        <p style={{marginBottom: 0}}>The Insights page offers historical statistics, and a collation of all your past events, providing a comprehensive view of your progress over time. As you continue to race with us, your data becomes increasingly refined and insightful. Key features include:</p>
                        <ul>
                            <li><strong>Overall Stats:</strong> Total laps, total distance driven, and total time driven.</li>
                            <li><strong>Event Rankings:</strong> Plot showing your ranking in each event.</li>
                            <li><strong>Gap to Best Lap Times:</strong> Visualization of your gaps to the fastest laps of all sessions in each event.</li>
                            <li><strong>Single Lap Performances:</strong> Track your single lap performance scores over time.</li>
                            <li><strong>Race Performances:</strong> Monitor your race performance scores for each event.</li>
                        </ul>
                        <p><strong>Customisation:</strong> Specify the number of events to display, allowing you to focus on recent performance trends. Hover over a data point on any graph to see the exact value.</p>
                    </section>

                    <section className="help-section">
                        <h4>SINGLE LAP PERFORMANCE (%)</h4>
                        <p style={{marginBottom: 0}}><strong>If your score is low:</strong></p>
                        <ul>
                            <li>In qualifying, don’t drive every lap at maximum effort. Alternate between a slower lap focusing on creating a gap from the driver in front and relaxing, followed by a qualifying focusing on smooth steering (and breathing!)</li>
                            <li>Check your racing lines, especially on corner entry. Are you positioned well to make the most of the straight after the corner?</li>
                            <li>Check your corner speed: going into a corner too fast will slow you down on the exit. Try slowing your entry speed (by braking earlier before the corner), allowing you to accelerate sooner on exit to make the most of the straight.</li>
                        </ul>
                    </section>
                
                    <section className="help-section">
                        <h4>RACE PERFORMANCE (%)</h4>
                        <p style={{marginBottom: 0}}><strong>If your score is low:</strong></p>
                        <ul>
                            <li>When you’re on the straights, take a deep breath to untense. Consider how you approached the upcoming corner last lap and try to do the same again this lap.</li>
                            <li>Work on one corner at a time: Identify a natural ‘marker’ on your track walk, use this to brake and apply the throttle at the same point on each lap.</li>
                            <li>Your Race Performance Score will improve over time as you find a rhythm of driving and keep your times consistent by minimizing errors.</li>
                            <li>Report to the pit lane in plenty of time for your session to give yourself a chance to relax and visualize your perfect start and first lap.</li>
                            <li>Remember: The race isn’t won at the first corner; take opportunities when they present themselves and avoid contact.</li>
                        </ul>
                    </section>
                
                    <section className="help-section last-column">
                        <h4>TIPS</h4>
                        <p style={{marginBottom: 0}}>Look at Single Lap Performance and Race Performance together to identify where your strengths are and which areas you can improve your performance.</p>
                        <ul>
                            <li>If you have a high Single Lap Score but a low Race Score, it means that you are able to drive fast but lack consistency. Focus on consistent-driving techniques.</li>
                            <li>If you have a high Race Score but a low Single Lap Score, it means that your consistency is good but you could be faster. Focus on speed techniques.</li>
                        </ul>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    )
}