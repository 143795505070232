import { TalentIdEvent } from "../../utils/models/TalentIdEvent";
import { TalentIdSession } from "../../utils/models/TalentIdSession";

export const readStoredData = (key) => {
    const storedData = sessionStorage.getItem(key);
    var data = null;
    if (storedData) {
        data = JSON.parse(storedData);
    }
    if (key === "events" && data) {
        data = data.map((event) => new TalentIdEvent(event));
    } else if (key === "sessions" && data) {
        data = data.map((session) => new TalentIdSession(session));
    }
    return data;
};
