import avatar from '../static/img/avatar.png';
import { useAuth } from "../provider/authProvider";
import '../static/css/account.css';
import NavBar from './NavBar';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Account() {
    const { token } = useAuth();
    const navigate = useNavigate();

    const handleChangePasswordClick = (e) => {
        navigate('/change-password');
    }

    return (
        <div className='account-page'>
            <NavBar />
            <main>

                <section className="account">
                        
                        <div className="account-container">

                            <div className="account-img">
                                <img className="avatar" src={avatar} alt="Avatar" />
                            </div>

                            <div className="account-body">

                                <div className="account-body-container">

                                    <div className="account-body-personal-info">
                    
                                        <div className="account-body-container-body">
                    
                                            <div className="account-body-container-body-field">
                    
                                                <h3 className="account-body-container-body-field-title">NAME</h3>
                                                <h3 className="account-body-container-body-field-value">{token.name}</h3>
                    
                                            </div>
                    
                                            <div className="account-body-container-body-field">
                    
                                                <h3 className="account-body-container-body-field-title">EMAIL</h3>
                                                <h3 className="account-body-container-body-field-value">{token.email}</h3>
                    
                                            </div>
                    
                                        </div>

                                    </div>

                                    <div className="account-body-change-password">
                                        <form onSubmit={handleChangePasswordClick}><input type="submit" value="CHANGE PASSWORD" /></form>
                                    </div>

                                </div>

                            </div>

                        </div>

                </section>

                </main>
                <Footer />
        </div>
    )
}