/**
 * Collates performance scores based on provided KPIs, group name, and user ID.
 *
 * @param {TalentIdKpi[]} kpis - The array of KPIs.
 * @param {string} groupName - The name of the group.
 * @param {string} userId - The ID of the user.
 * @returns {Object} The collated performance scores.
 */
export const collatePerformanceScores = (kpis, groupName, labelKey, userId) => {
    var final_kpi = [];
    var count = 0;
    for (let i = 0; i < kpis.length; i++) {
        const kpi = kpis[i];
        const point = [kpi.metadata.ranking, kpi.metadata.percScore, kpi.userId === userId];
        final_kpi.push(point);
        count++;
    }
    final_kpi = final_kpi.sort((a, b) => a[0] - b[0]);
    for (let i = 0; i < final_kpi.length; i++) {
        final_kpi[i][0] = i + 1;
    }
    const labels = {
        BestLap: "Single Lap (%)",
        FinalPace: "Race (%)"
    };
    if (!(labelKey in labels)) {
        return {};
    }
    return {
        name: groupName,
        yLabel: labels[labelKey],
        xLabel: "Rank",
        points: final_kpi,
        type: "performance"
    };
};
