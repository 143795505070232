export class TalentIdSession {
    constructor({ id, eventId, name, type, category, startDate, wasMixed }) {
        this.id = id;
        this.eventId = eventId;
        this.name = name;
        this.type = type;
        this.category = category;
        this.startDate = this.transformDate(startDate);
        this.wasMixed = wasMixed;
    }

    transformDate(date) {
        return new Date(date);
    }
}