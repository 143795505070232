/**
 * Retrieves the overview key performance indicator (KPI) object.
 *
 * @param {TalentIdKpi} kpi - The KPI object.
 * @param {string} category - The category of the KPI.
 * @param {number} maximum_ranking - The maximum ranking value.
 * @returns {Object} - The overview KPI object.
 */
export const getOverviewKpi = (kpi, category, maximum_ranking) => {
    const display_names_keys = ["BestLap_Event_" + category, "FinalPace_Event_" + category, "BestLap_Session_Rank", "Final_Session_Rank"];
    const display_names = {};
    display_names[display_names_keys[0]] = "Single Lap Rank";
    display_names[display_names_keys[1]] = "Race Rank";
    display_names[display_names_keys[2]] = "Single Lap Rank";
    display_names[display_names_keys[3]] = "Race Rank";

    return {
        name: kpi.groupId,
        displayName: display_names[kpi.groupId],
        value: kpi.metadata.ranking,
        maximumValue: maximum_ranking,
        type: "ranking"
    };
};
