/**
 * Retrieves the average performance for a group of KPIs.
 *
 * @param {TalentIdKpi[]} kpis - The KPIs to calculate the average performance for.
 * @param {string} name - The name of the average performance.
 *
 * @returns {Object} An object containing the name, value, and units of the average performance.
 */
export const getAveragePerformance = (kpis, name) => {
    if (kpis.length === 0) return null;
    const value = Math.round(kpis.reduce((acc, kpi) => acc + kpi.metadata.percScore, 0) / kpis.length * 100) / 100;
    return {
        name,
        value,
        units: "%"
    };
};
