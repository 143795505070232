/**
 * Gets performance graph from performance KPIs.
 *
 * @param {Object[]} performanceKpis - The performance KPIs.
 * @param {Object} graph_map - The graph map object.
 *
 * @returns {Promise<Object>} A promise that resolves with the performance graph.
 */
export const getPerformanceGraph = async (performanceKpis, { name, displayName, description, yLabel, xLabel }) => {
    const points = [];
    for (const kpi of performanceKpis) {
        const label = kpi.metadata.label + "'" + new Date(kpi.metadata.timestamp).getFullYear().toString().slice(-2);
        points.push([label, kpi.metadata.percScore, kpi.metadata.timestamp]);
    }
    return {
        name,
        displayName,
        description,
        yLabel,
        xLabel,
        points,
        type: "graphical"
    };
};
