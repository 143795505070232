import logo from './logo.svg';
import './App.css';
import './static/css/base.css';
import AuthProvider from "./provider/authProvider";
import Routes from "./routes";
import { AjaxHelper } from './utils/AjaxHelper';

function App() {
  // const baseUrl = process.env.BASE_URL || "http://localhost:5002/api/v1";
  // const baseUrl = "https://d30je658kxbq9.cloudfront.net";
  const baseUrl = process.env.REACT_APP_API_URL;
  const ajaxHelper = new AjaxHelper(baseUrl);
  const healthResponse = ajaxHelper.get("/health");
  console.log(healthResponse);

  return (
    <AuthProvider>
      <Routes ajaxHelper={ajaxHelper} />
    </AuthProvider>
  );
}

export default App;
