import { TalentIdKpi } from "../models/TalentIdKpi";

/**
 * Retrieves the best lap KPIs for a specific event and group.
 *
 * @param {string} eventId - The ID of the event.
 * @param {string} groupId - The ID of the group.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The authentication token.
 * @returns {Promise<TalentIdKpi[]>} - A promise that resolves to the response data containing the best lap KPIs.
 */
export const getKpisByEvent = async (eventId, groupId, ajaxHelper, token) => {
    const response = await ajaxHelper.get("/Kpi/byEvent/" + eventId, token, { groupId });
    const kpis = response.data.map(kpi => new TalentIdKpi(kpi));
    return kpis;
};
