import { useNavigate } from "react-router-dom";

export default function Form({ current_page, path, value, onSubmit }) {
    const navigate = useNavigate();
    const onClick = (e) => {
        e.preventDefault();
        navigate(path);
    }
    return (
        <form onClick={onClick} onSubmit={onSubmit}>
            <input type="submit" value={value} className={current_page === path ? "desktop-nav-link selected" : "desktop-nav-link"} />
        </form>
    )
}