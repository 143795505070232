export const graph_map = {
    BestLap_Event: {
        name: "BestLapPerformance",
        displayName: "Single Lap Performances",
        description: "YOUR PAST SINGLE LAP PERFORMANCE SCORES FOR THE EVENTS YOU HAVE PARTICIPATED IN.",
        yLabel: "Single Lap Performance (%)",
        xLabel: "Event"
    },
    FinalPace_Event: {
        name: "FinalPace",
        displayName: "Race Performances",
        description: "YOUR PAST RACE PERFORMANCE SCORES FOR THE EVENTS YOU HAVE PARTICIPATED IN.",
        yLabel: "Race Performance (%)",
        xLabel: "Event"
    },
    BestLapRanking: {
        name: "BestLapRanking",
        displayName: "Single Lap Rankings",
        description: "YOUR PAST SINGLE LAP RANKINGS FOR THE EVENTS YOU HAVE PARTICIPATED IN.",
        yLabel: "Single Lap Rank",
        xLabel: "Event"
    },
    FinalPaceRanking: {
        name: "FinalPaceRanking",
        displayName: "Race Pace Rankings",
        description: "YOUR PAST RACE PERFORMANCE RANKINGS FOR THE EVENTS YOU HAVE PARTICIPATED IN.",
        yLabel: "Race Pace Rank",
        xLabel: "Event"
    }
};
