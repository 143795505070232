import { TalentIdDriver } from "./TalentIdDriver";
import { TalentIdLap } from "./TalentIdLap";

export class TalentIdCompetitor {
    constructor({ id, eventId, sessionId, driverId, laps, racingTime, startPosition, endPosition, name, idByBooking, talentIdDriver }) {
        this.id = id;
        this.eventId = eventId;
        this.sessionId = sessionId;
        this.driverId = driverId;
        this.laps = laps.map(lap => new TalentIdLap(lap));
        this.racingTime = racingTime;
        this.startPosition = startPosition;
        this.endPosition = endPosition;
        this.name = name;
        this.idByBooking = idByBooking;
        this.talentIdDriver = new TalentIdDriver(talentIdDriver);
    }
}