import { TalentIdSession } from "../models/TalentIdSession";

/**
 * Retrieves sessions by event, user, and types.
 * @param {string} eventId - The ID of the event.
 * @param {string} userId - The ID of the user.
 * @param {string[]} types - An array of session types.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object.
 * @param {string} token - The authentication token.
 * @returns {Promise<TalentIdSession[]>} - A promise that resolves to an array of filtered TalentIdSession objects.
 */
export const getSessionsByEventByUserByType = async (eventId, userId, types, ajaxHelper, token) => {
    const response = await ajaxHelper.get("/Sessions/byEvent/" + eventId + "/byUser/" + userId, token);
    const sessions = response.data.map(session => new TalentIdSession(session));
    if (types.length === 0) {
        return sessions;
    }
    const filteredSessions = sessions.filter(session => types.includes(session.type));
    return filteredSessions;
};
