import * as d3 from "d3";

function ordinal_suffix_of(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}

function plotMultiplePastEvents(data, eventNumber) {
    const plotName = data[0].id;
    const displayName = data[0].display.name;
    const description = data[0].display.description;

    const units = [];
    data.forEach((kpi) => {
        const currUnits = kpi.data.yUnits;
        const currUnitsFirstCharacter = Array.from(currUnits)[0].toUpperCase();
        if (!units.includes(currUnitsFirstCharacter)) {
            units.push(currUnitsFirstCharacter);
        }
    });

    const section = document.createElement("div");
    section.id = plotName.replace(/\s+/g, '') + "-chart";
    section.classList.add("insights-chart");
    section.setAttribute("data-type", "kpi-plot");
    section.setAttribute("data-event-number", eventNumber);
    section.setAttribute("data", JSON.stringify(data));

    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = displayName.toUpperCase();

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");
    informationContainer.id = plotName.replace(/\s+/g, '') + "-info";

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("insights-information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = plotName.replace(/\s+/g, '') + "-img";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.classList.add("insights-popup");
    informationPopup.id = plotName.replace(/\s+/g, '') + "-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = description.toUpperCase();

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const toggle = document.createElement("div");
    toggle.id = plotName.replace(/\s+/g, '') + "-toggle";
    toggle.classList.add("insights-chart-toggle");
    
    const toggleLabel = document.createElement("label");
    toggleLabel.classList.add("toggle");

    const toggleInput = document.createElement("input");
    toggleInput.type = "checkbox";

    const toggleSpan = document.createElement("span");
    toggleSpan.classList.add("slider");

    const toggleSpanOptions = document.createElement("span");
    toggleSpanOptions.classList.add("labels");
    toggleSpanOptions.setAttribute("data-on", units[0].toUpperCase());
    toggleSpanOptions.setAttribute("data-off", units[1].toUpperCase());

    toggleLabel.appendChild(toggleInput);
    toggleLabel.appendChild(toggleSpan);
    toggleLabel.appendChild(toggleSpanOptions);

    toggle.appendChild(toggleLabel);

    toggleInput.onclick = function() {
        var selected = this.checked ? units[1] : units[0];
        document.getElementById(plotName.replace(/\s+/g, '') + "-" + units[0]).style.display = selected === units[0] ? "block" : "none";
        document.getElementById(plotName.replace(/\s+/g, '') + "-" + units[1]).style.display = selected === units[1] ? "block" : "none";
        // setCookie("preferred_units", (selected === "%" ? "percent" : "s"), 1);
    }

    const eventNumberSelect = document.getElementsByClassName("event-number-selection")[0];
    if (window.getComputedStyle(eventNumberSelect).order === "2" && eventNumberSelect.getElementsByClassName("insights-chart-toggle").length === 0) {
        informationContainer.appendChild(toggle);
        eventNumberSelect.appendChild(informationContainer);
    } else {
        titleName.appendChild(toggle);
    }

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");
    desc.innerHTML = description.toUpperCase();

    title.appendChild(titleName);
    title.appendChild(desc);
    section.appendChild(title);

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 20, bottom: 50, left: 50 };
    const width = wholeContainer.offsetWidth - margin.left - margin.right;

    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const statistics = document.getElementsByClassName("stats-wrapper")[0].offsetHeight;
    const insightsTitleWrapper = document.getElementsByClassName("insights-title-wrapper")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight;
    const plotTitleHeight = window.getComputedStyle(title).display === "block" ? parseInt(window.getComputedStyle(title).height) : 0;
    const eventNumberDisplay = document.getElementsByClassName("event-number-selection-container")[0].offsetHeight;
    const mainPadding = window.getComputedStyle(metricOptionsContainer).display === "block" ? 100 : 50;
    // const footer = document.getElementsByTagName("footer")[0].offsetHeight;
    // const height = (windowHeight - header - footer - statistics - eventNumberDisplay - margin.top) * 0.8;
    const height = windowHeight
         - header 
         - statistics 
         - insightsTitleWrapper
         - metricOptionsContainerHeight
         - plotTitleHeight
         - eventNumberDisplay
         - mainPadding 
         - margin.top
         - margin.bottom;
    
    var selected = units[0];

    for (let i = 0; i < data.length; i++) {
        
        var currUnits = Array.from(data[i].data.yUnits)[0].toUpperCase();
        const svg = d3.select("#"+plotName.replace(/\s+/g, '')+"-chart .insights-chart-plot")
            .append("svg")
            .attr("id", plotName.replace(/\s+/g, '') + "-" + currUnits)
            .style("display", currUnits === selected ? "block" : "none")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);


        const xLabel = data[i].data.xLabel;
        const yLabel = data[i].data.yLabel + " (" + currUnits + ")";

        var points = data[i].data.points.slice(d3.max([data[i].data.points.length - eventNumber, 0]), data[i].data.points.length);
        var pointIndex = 1;
        var catExists = false;
        if (points[0].length !== 2) {
            catExists = true;
        }
        if (catExists) {
            // points = points.map((point) => [point[0] + " " + point[2], point[3]]);
            points = points.map((point) => [point[0], point[3]]);
        }
        points = points.map((point) => [point[0], point[1], currUnits])
        const eventNames = points.map((point) => point[0]);

        var maximum, minimum;
        if (plotName.includes("bestLapPointsPastEvent") || plotName.includes("racePointsPastEvent")) {
            maximum = 100;
            minimum = 0;
        } else if (plotName.includes("pastEventRanking")) {
            minimum = 1;
            maximum = d3.max(points.map((point) => point[pointIndex]));
        } else if (plotName.includes("pastEventGap")) {
            minimum = 0;
            maximum = d3.max(points.map((point) => point[pointIndex]));
        } else {
            maximum = d3.max(points.map((point) => point[pointIndex])) + 1;
            minimum = d3.min(points.map((point) => point[pointIndex])) - 1;
        }

        const xScale = d3.scaleBand()
            .domain(eventNames)
            .range([0, width])
            .padding(0.2);

        const yScale = d3.scaleLinear()
            .domain([minimum, maximum])
            .nice()
            .range([height, 0]);

        const xAxisGrid = d3.axisBottom(xScale).tickSize(-height).tickFormat('').ticks(10);
        const yAxisGrid = d3.axisLeft(yScale).tickSize(-width).tickFormat('').ticks(10);

        svg.append('g')
            .attr('class', 'x axis-grid')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxisGrid);
        svg.append('g')
            .attr('class', 'y axis-grid')
            .call(yAxisGrid);

        svg.append("g")
            .attr("class", "x-axis axis")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale))
            .selectAll("text")  
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-15) translate(0, 11)");

        if (plotName === "pastEventRanking") {
            const yAxisTicks = yScale.ticks()
                .filter(tick => Number.isInteger(tick));

            svg.append("g")
                .attr("class", "y-axis axis")
                .call(d3.axisLeft(yScale).tickValues(yAxisTicks).tickFormat(d3.format(".0f")));
        } else {
            svg.append("g")
                .attr("class", "y-axis axis")
                .call(d3.axisLeft(yScale));
        }

        const line = d3.line()
            .x((d) => xScale(d[0]) + xScale.bandwidth() / 2)
            .y((d) => yScale(d[pointIndex]));

        svg.append("path")
            .datum(points)
            .attr("fill", "none")
            .attr("stroke", "rgb(25, 240, 45)")
            .attr("stroke-width", 2)
            .attr("d", line);

        var Tooltip = d3.select("#"+plotName.replace(/\s+/g, '') + "-chart .insights-chart-plot")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("border-color", "white")
            .style("padding", "5px")
            .style("color", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .style("position", "absolute")

        // Three function that change the tooltip when user hover / move / leave a cell

        var mouseover = function(d) {
            Tooltip
                .style("opacity", 1)
            d3.select(this)
                .style("stroke", "black")
                .style("opacity", 1)
        }
        function getTooltipValue(value, unit) {
            return unit === "%" ? value + unit : value + " " + unit;
        }

        var mousemove = function(e, d) {
            Tooltip
                .html(d[0] + ": " + getTooltipValue(d[pointIndex], d[2]))
                .style("left", (e.pageX + 10) + "px")
                .style("top", (e.pageY) + "px")
        }

        var mouseleave = function(d) {
            Tooltip
                .style("opacity", 0)
                .style("left", "0px")
                .style("top", "0px");
            d3.select(this)
                .style("stroke", "none");
        }

        svg
            .selectAll("circle")
            .data(points)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return xScale(d[0]) + xScale.bandwidth() / 2; } )
            .attr("cy", function (d) { return yScale(d[pointIndex]); } )
            .attr("r", 5)
            .style("fill", "rgb(25, 240, 45)")
            .on("mouseover", mouseover)
            .on("mousemove", function(e) {mousemove(e, e.target.__data__)})
            .on("mouseleave", mouseleave);

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(yLabel);
    }


}

function toggleableGraphicalPlotter(data, eventNumber) {
    const name = data.name;
    const displayName = data.displayName;
    const description = data.description;
    const xLabel = data.xLabel;
    const yLabel = data.yLabel;
    const points = data.points;
    const unitMapping = {"seconds": "s", "percentage": "%"}
    const units = Object.keys(points);
    var selected = units[0]; //getCookie("preferred_unit") || 

    const section = document.createElement("div");
    section.id = name + "-chart";
    section.classList.add("insights-chart");
    
    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = displayName.toUpperCase();

    const toggle = document.createElement("div");
    toggle.classList.add("insights-chart-toggle");

    const toggleLabel = document.createElement("label");
    toggleLabel.classList.add("toggle");

    const toggleInput = document.createElement("input");
    toggleInput.type = "checkbox";

    const toggleSpan = document.createElement("span");
    toggleSpan.classList.add("slider");

    const toggleSpanOptions = document.createElement("span");
    toggleSpanOptions.classList.add("labels");
    toggleSpanOptions.setAttribute("data-on", unitMapping[units[0]].toUpperCase());
    toggleSpanOptions.setAttribute("data-off", unitMapping[units[1]].toUpperCase());

    toggleLabel.appendChild(toggleInput);
    toggleLabel.appendChild(toggleSpan);
    toggleLabel.appendChild(toggleSpanOptions);

    toggle.appendChild(toggleLabel);

    toggleInput.onclick = function() {
        var selected = this.checked ? units[1] : units[0];
        // setCookie("preferred_unit", selected, 1);
        document.getElementById(name + "-" + units[0]).style.display = selected === units[0] ? "block" : "none";
        document.getElementById(name + "-" + units[1]).style.display = selected === units[1] ? "block" : "none";
    }

    titleName.appendChild(toggle);

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");
    desc.innerHTML = description.toUpperCase();

    title.appendChild(titleName);
    title.appendChild(desc);
    section.appendChild(title);

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");
    informationContainer.id = name + "-info";

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("insights-information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = name + "-img";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.classList.add("insights-popup");
    informationPopup.id = name + "-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = description.toUpperCase();

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const eventNumberSelect = document.getElementsByClassName("event-number-selection")[0];
    if (window.getComputedStyle(eventNumberSelect).order === "2") {
        const oldToggle = eventNumberSelect.getElementsByClassName("insights-chart-toggle");
        if (oldToggle.length === 0) {
            informationContainer.appendChild(toggle);
        } else {
            for (let i = 0; i < oldToggle.length; i++) {
                const toggleInput = oldToggle[i].getElementsByTagName("input")[0];
                toggleInput.checked = (selected === units[1])
            }
        }
        if (document.getElementById(name + "-info") === null) {
            eventNumberSelect.appendChild(informationContainer);
        }
    }

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");

    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 20, bottom: 100, left: 50 };
    const width = wholeContainer.offsetWidth - margin.left - margin.right;
    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const statistics = document.getElementsByClassName("stats-wrapper")[0].offsetHeight;
    const insightsTitleWrapper = document.getElementsByClassName("insights-title-wrapper")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight;
    var plotTitleHeight = 0;
    if (window.getComputedStyle(title).display === "block") {
        section.style.display = "block";
        plotTitleHeight = title.offsetHeight;
        section.style.display = "none";
    }
    const eventNumberDisplay = document.getElementsByClassName("event-number-selection-container")[0].offsetHeight;
    const mainPadding = window.getComputedStyle(metricOptionsContainer).display === "block" ? 100 : 50;

    const height = windowHeight
         - header 
         - statistics 
         - insightsTitleWrapper
         - metricOptionsContainerHeight
         - plotTitleHeight
         - eventNumberDisplay
         - mainPadding 
         - margin.top
         - margin.bottom;


    for (let i = 0; i < units.length; i++) {
        const currUnits = units[i];
        var graphName = name + "-" + currUnits;
        const currData = points[currUnits].slice(d3.max([points[currUnits].length - eventNumber, 0]), points[currUnits].length);
        const eventNames = currData.map((point) => point[0]);
        const maximum = d3.max(currData.map((point) => point[1])) + 0.1;
        const minimum = d3.max([d3.min(currData.map((point) => point[1])) - 1, 0]);
        const thisYLabel = yLabel + " (" + unitMapping[currUnits] + ")";

        const svg = d3.select("#"+name+"-chart .insights-chart-plot")
            .append("svg")
            .attr("id", graphName)
            .style("display", currUnits === selected ? "block" : "none")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleBand()
            .domain(eventNames)
            .range([0, width])
            .padding(0.2);

        const yScale = d3.scaleLinear()
            .domain([minimum, maximum])
            .nice()
            .range([height, 0]);

        const xAxisGrid = d3.axisBottom(xScale).tickSize(-height).tickFormat('').ticks(10);
        const yAxisGrid = d3.axisLeft(yScale).tickSize(-width).tickFormat('').ticks(10);

        svg.append('g')
            .attr('class', 'x axis-grid')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxisGrid);

        svg.append('g')
            .attr('class', 'y axis-grid')
            .call(yAxisGrid);

        svg.append("g")
            .attr("class", "x-axis axis")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale))
            .selectAll("text")  
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-15) translate(0, 11)");

        svg.append("g")
            .attr("class", "y-axis axis")
            .call(d3.axisLeft(yScale));

        const line = d3.line()
            .x((d) => xScale(d[0]) + xScale.bandwidth() / 2)
            .y((d) => yScale(d[1]));

        svg.append("path")
            .datum(currData)
            .attr("fill", "none")
            .attr("stroke", "rgb(25, 240, 45)")
            .attr("stroke-width", 2)
            .attr("d", line);

        var Tooltip = d3.select("#"+name+"-chart .insights-chart-plot")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("border-color", "white")
            .style("padding", "5px")
            .style("color", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .style("position", "absolute")

        // Three function that change the tooltip when user hover / move / leave a cell

        var mouseover = function(d) {
            Tooltip
                .style("opacity", 1)
            d3.select(this)
                .style("stroke", "black")
                .style("opacity", 1)
        }
        function getTooltipValue(value, currUnits) {
            return value + unitMapping[currUnits];
        }
        var mousemove = function(e, d, currUnits) {
            Tooltip
                .html(d[0] + ": " + getTooltipValue(d[1], currUnits))
                .style("left", (e.pageX + 10) + "px")
                .style("top", (e.pageY) + "px")
        }
        var mouseleave = function(d) {
            Tooltip
                .style("opacity", 0)
                .style("left", "0px")
                .style("top", "0px");
            d3.select(this)
                .style("stroke", "none");
        }

        svg
            .selectAll("circle")
            .data(currData)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return xScale(d[0]) + xScale.bandwidth() / 2; } )
            .attr("cy", function (d) { return yScale(d[1]); } )
            .attr("r", 5)
            .style("fill", "rgb(25, 240, 45)")
            .on("mouseover", mouseover)
            .on("mousemove", function(e) {mousemove(e, e.target.__data__, currUnits)})
            .on("mouseleave", mouseleave);

        svg.append("text")
            .attr("y", height + margin.top + margin.bottom / 2)
            .attr("x", width / 2)
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(xLabel);

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
            .text(thisYLabel);
    }

    if (toggleInput.checked !== (selected === units[1])) {
        toggleInput.click();
    }
}

function graphicalPlotter(data, eventNumber) {
    const name = data.name;
    const displayName = data.displayName;
    const description = data.description;
    const xLabel = data.xLabel;
    const yLabel = data.yLabel;
    const points = data.points.slice(d3.max([data.points.length - eventNumber, 0]), data.points.length);

    const section = document.createElement("div");
    section.id = name + "-chart";
    section.classList.add("insights-chart");

    const title = document.createElement("div");
    title.classList.add("insights-chart-title");

    const titleName = document.createElement("div");
    titleName.classList.add("insights-chart-name");
    titleName.innerHTML = displayName.toUpperCase();

    const desc = document.createElement("div");
    desc.classList.add("insights-chart-description");
    desc.innerHTML = description.toUpperCase();

    title.appendChild(titleName);
    title.appendChild(desc);

    section.appendChild(title);

    const informationContainer = document.createElement("div");
    informationContainer.classList.add("information-container");
    informationContainer.id = name + "-info";

    const informationImg = document.createElement("img");
    informationImg.src = "/info-icon.png";
    informationImg.classList.add("insights-information-icon");
    informationImg.alt = "Information Icon";
    informationImg.id = name + "-img";

    const informationPopup = document.createElement("div");
    informationPopup.classList.add("popup");
    informationPopup.classList.add("insights-popup");
    informationPopup.id = name + "-popup";

    const informationText = document.createElement("p");
    informationText.innerHTML = description.toUpperCase();

    informationPopup.appendChild(informationText);

    informationContainer.appendChild(informationImg);
    informationContainer.appendChild(informationPopup);

    informationImg.addEventListener("click", function(event) {
        informationPopup.classList.toggle("show");
    });

    const eventNumberSelect = document.getElementsByClassName("event-number-selection")[0];
    if (
        window.getComputedStyle(eventNumberSelect).order === "2" && 
        eventNumberSelect.getElementsByClassName("insights-chart-toggle").length === 0 &&
        document.getElementById(name + "-info") === null
        ) {
        eventNumberSelect.appendChild(informationContainer);
    }

    const plotContent = document.createElement("div");
    plotContent.classList.add("insights-chart-content");
    
    const plot = document.createElement("div");
    plot.classList.add("insights-chart-plot");

    plotContent.appendChild(plot);
    section.appendChild(plotContent);

    const wholeContainer = document.getElementsByClassName("insights-chart-container")[0];
    wholeContainer.appendChild(section);

    const margin = { top: 10, right: 20, bottom: 100, left: 50 };
    const width = wholeContainer.offsetWidth - margin.left - margin.right;
    const windowHeight = window.innerHeight;
    const header = document.getElementsByTagName("header")[0].offsetHeight;
    const statistics = document.getElementsByClassName("stats-wrapper")[0].offsetHeight;
    const insightsTitleWrapper = document.getElementsByClassName("insights-title-wrapper")[0].offsetHeight;
    const metricOptionsContainer = document.getElementsByClassName("metric-options-container")[0];
    const metricOptionsContainerHeight = window.getComputedStyle(metricOptionsContainer).display === "block" ? 0 : metricOptionsContainer.offsetHeight;
    var plotTitleHeight = 0;
    if (window.getComputedStyle(title).display === "block") {
        section.style.display = "block";
        plotTitleHeight = title.offsetHeight;
        section.style.display = "none";
    }
    const eventNumberDisplay = document.getElementsByClassName("event-number-selection-container")[0].offsetHeight;
    const mainPadding = window.getComputedStyle(metricOptionsContainer).display === "block" ? 100 : 50;

    const height = windowHeight
         - header 
         - statistics 
         - insightsTitleWrapper
         - metricOptionsContainerHeight
         - plotTitleHeight
         - eventNumberDisplay
         - mainPadding 
         - margin.top
         - margin.bottom;

    const svg = d3.select("#"+name+"-chart .insights-chart-plot")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleBand()
        .domain(points.map((point) => point[0]))
        .range([0, width])
        .padding(0.2);

    var maximum, minimum;
    if (name === "FinalPace" || name === "BestLapPerformance") {
        maximum = 100;
        minimum = 0;
    } else if (name === "BestLapRanking" || name === "FinalPaceRanking") {
        minimum = 1;
        maximum = d3.max(points.map((point) => point[1]));
    } else {
        maximum = d3.max(points.map((point) => point[1])) + 1;
        minimum = d3.min(points.map((point) => point[1])) - 1;
    }
    const yScale = d3.scaleLinear()
        .domain([minimum, maximum])
        .nice()
        .range([height, 0]);

    const xAxisGrid = d3.axisBottom(xScale).tickSize(-height).tickFormat('').ticks(10);
    const yAxisGrid = d3.axisLeft(yScale).tickSize(-width).tickFormat('').ticks(10);

    svg.append('g')
        .attr('class', 'x axis-grid')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxisGrid);
    svg.append('g')
        .attr('class', 'y axis-grid')
        .call(yAxisGrid);

    svg.append("g")
        .attr("class", "x-axis axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale))
        .selectAll("text")  
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-15) translate(0, 11)");

    if (name === "BestLapRanking" || name === "FinalPaceRanking") {
        const yAxisTicks = yScale.ticks()
            .filter(tick => Number.isInteger(tick));

        svg.append("g")
            .attr("class", "y-axis axis")
            .call(d3.axisLeft(yScale).tickValues(yAxisTicks).tickFormat(d3.format(".0f")));
    } else {
        svg.append("g")
            .attr("class", "y-axis axis")
            .call(d3.axisLeft(yScale));
    }

    const line = d3.line()
        .x((d) => xScale(d[0]) + xScale.bandwidth() / 2)
        .y((d) => yScale(d[1]));

    var path = svg.append("path")
        .datum(points)
        .attr("fill", "none")
        .attr("stroke", "rgb(25, 240, 45)")
        .attr("stroke-width", 2)
        .attr("d", line);

    // const pathLength = path.node().getTotalLength();
    // path
    //     .attr("stroke-dashoffset", pathLength)
    //     .attr("stroke-dasharray", pathLength)
    //     .transition()
    //     .ease(d3.easeSin)
    //     .duration(2500)
    //     .attr("stroke-dashoffset", 0);
    
    var Tooltip = d3.select("#"+name+"-chart .insights-chart-plot")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("border-color", "white")
        .style("padding", "5px")
        .style("color", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .style("position", "absolute")

    // Three function that change the tooltip when user hover / move / leave a cell

    var mouseover = function(d) {
        Tooltip
            .style("opacity", 1)
        d3.select(this)
            .style("stroke", "black")
            .style("opacity", 1)
    }
    function getTooltipValue(value) {
        return (name === "BestLapRanking" || name === "FinalPaceRanking") ? value.toFixed(0) + ordinal_suffix_of(value) :
            name === "FinalPace" || name === "BestLapPerformance" ? value.toFixed(2).replace(/[.,]00$/, "") + "%" : value.toFixed(2).replace(/[.,]00$/, "");
    }
    var mousemove = function(e, d) {
        var label = d[0];
        var value = d[1];
        Tooltip
            .html(label + ": " + getTooltipValue(value))
            .style("left", (e.pageX + 10) + "px")
            .style("top", (e.pageY) + "px")
    }
    var mouseleave = function(d) {
        Tooltip
            .style("opacity", 0)
            .style("left", "0px")
            .style("top", "0px");
        d3.select(this)
            .style("stroke", "none");
    }

    svg
        .selectAll("circle")
        .data(points)
        .enter()
        .append("circle")
        .attr("cx", function (d) { return xScale(d[0]) + xScale.bandwidth() / 2; } )
        .attr("cy", function (d) { return yScale(d[1]); } )
        .attr("r", 5)
        .style("fill", "rgb(25, 240, 45)")
        .on("mouseover", mouseover)
        .on("mousemove", function(e) {mousemove(e, e.target.__data__)})
        .on("mouseleave", mouseleave);

    svg.append("text")
        .attr("y", height + margin.top + margin.bottom / 2)
        .attr("x", width / 2)
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .text(xLabel);

    svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-family", "Chakra-Petch-Semi-Bold-Italic")
        .text(yLabel);
}

export function historicalPlotter(data, eventNumber) {
    document.getElementsByClassName("insights-chart-container")[0].innerHTML = "";
    const graphical = data.filter((kpi) => kpi.type === "graphical");
    const toggleableGraphical = data.filter((kpi) => kpi.type === "toggleableGraphical");
    graphical.forEach((kpi) => {
        graphicalPlotter(kpi, eventNumber);
    });
    toggleableGraphical.forEach((kpi) => {
        toggleableGraphicalPlotter(kpi, eventNumber);
    });
}

function generateMetricSelect(data, selectedMetric, setSelectedMetric) {
    const metricSelect = document.createElement("div");
    metricSelect.classList.add("metric-name");
    
    const button = document.createElement("button");
    button.classList.add("metric-button");
    button.innerHTML = data.displayName.toUpperCase();
    button.id = data.name;

    button.addEventListener("click", function (event) {
        const allMetricButtons = document.querySelectorAll(".metric-name .selected");
        for (var i = 0; i < allMetricButtons.length; i++) {
            const button = allMetricButtons[i];
            button.classList.remove("selected");
        }
        const metricName = this.id;
        this.classList.add("selected");
        setSelectedMetric(metricName);
        const informationContainers = document.getElementsByClassName("information-container");
        const metricNameContainer = document.getElementsByClassName("metric-options")[0];
        for (var i = 0; i < informationContainers.length; i++) {
            const container = informationContainers[i];
            if (container.id === metricName + "-info" && window.getComputedStyle(metricNameContainer).display === "flex") {
                container.style.display = "flex";
            } else {
                container.style.display = "none";
            }
        }
        const allMetricPlots = document.getElementsByClassName("insights-chart");
        for (var i = 0; i < allMetricPlots.length; i++) {
            const plot = allMetricPlots[i];
            if (plot.id === metricName + "-chart") {
                plot.style.display = "block";
            } else {
                plot.style.display = "none";
            }
        }
    });

    metricSelect.appendChild(button);

    return metricSelect;
}

export function generateAllMetricSelect(data, selectedMetric, setSelectedMetric) {
    const metricNameContainer = document.getElementsByClassName("metric-options")[0];
    metricNameContainer.innerHTML = "";
    data.forEach((d) => {
        const metricSelect = generateMetricSelect(d, selectedMetric, setSelectedMetric);
        metricNameContainer.appendChild(metricSelect);
    });
}