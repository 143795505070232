import axios from 'axios';

export class AjaxHelper {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.instance = axios.create({
            baseURL: baseUrl,
            timeout: 3000,
            headers: {
                "Content-Type": "application/json",
            }
        });

        this.instance.interceptors.response.use(
            response => response,
            error => {
                return Promise.reject(error);
            }
        );
    }

    async get (url, apiKey, params) {
        
        return await this.instance.get(url, {
            headers: {
                "x-api-key": apiKey
            },
            params: params
        });
    }

    async post (url, data, apiKey) {
        return await this.instance.post(url, data, {
            headers: {
                "x-api-key": apiKey
            }
        });
    }
}