import { getKpisBySession } from "./getKpisBySession";

/**
 * Retrieves the race pace graph data for a specific event and user.
 *
 * @param {TalentIdSession[]} sessions - The array of sessions.
 * @param {AjaxHelper} ajaxHelper - The AJAX helper object used for making API requests.
 * @param {string} token - The JWT token used for authentication.
 * @returns {Promise<Object>} A promise that resolves with the race pace graph data.
 */
export const getRacePaceGraph = async (sessions, userId, ajaxHelper, token) => {
    const points = {};
    for (const session of sessions) {
        const session_kpis = await getKpisBySession(session.id, "Final_Session_Rank", ajaxHelper, token);
        for (const kpi of session_kpis) {
            if (!(kpi.userId in points)) {
                points[kpi.userId] = [];
            }
            points[kpi.userId].push([session.name, kpi.metadata.avgPace / 1000, kpi.userId === userId]);
        }
    }
    const unwrapped_points = [];
    for (const user in points) {
        unwrapped_points.push(points[user]);
    }
    if (unwrapped_points.length === 0) return null;

    return {
        name: "RacePaceTimes",
        displayName: "Race Pace",
        description: "This graph shows your average lap time in every race session in which you have participated in green.\nYour competitors average lap time is shown in grey. If you have the fastest average lap time, you win the race!",
        yLabel: "Lap Time",
        xLabel: "Session",
        points: unwrapped_points,
        type: "graphical"
    };

};
